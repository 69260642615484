import logging from '@sstdev/lib_logging';
import _getHttpHeaders from './getHttpHeaders';
import globalConfig from '../globalConfig';
import getFullURL from './getFullURL';
import getQueryUrl from './getQueryUrl';
import { getToken } from '../session';
import { getActiveUseCaseAndTenant } from '../session/sessionCache';

const _p = {
    globalConfig
};
export const _private = _p;
export const getHttpHeaders = _getHttpHeaders;

export function getUrlForRelation() {
    // This does not exist anymore but is often used in forceInventorySync and purgeSync
    throw new Error('not implemented');
}

export const httpStatus = Object.freeze({
    /** 200 - Request Succeeded - Result in Body */
    OK: 200,
    /** 201 - Object Created - Result in Body */
    CREATED: 201,
    /** 202 - Request Accepted but didn't finish execution yet - No result available */
    ACCEPTED: 202,
    /** 204 - Request Accepted there is nothing to return - No result available*/
    NO_CONTENT: 204,
    /** 304 - Request Accepted but no modifications happened since if-modified-since - No result available*/
    NOT_MODIFIED: 304,
    /** 400 - Bad Request (client side mess up) - Body might include details*/
    BAD_REQUEST: 400,
    /** 401 - Unauthorized - user lacks valid authentication credentials*/
    UNAUTHORIZED: 401,
    /** 402 - Payment Require - Contract Expired or Licenses depleted  */
    PAYMENT_REQUIRED: 402,
    /** 403 - Forbidden - user is authenticated, but has no authorization for the requested resource/operation */
    FORBIDDEN: 403,
    /** 404 - Not found - the requested resource could not be located on the server */
    NOT_FOUND: 404,
    /** 409 - Conflict - Server will resolve with socket message, but returning for non-backbone API clients  */
    CONFLICT: 409,
    /** 500 - Request Failed (server side mess up) - Body will contain generic message*/
    SERVER_ERROR: 500
});

export class HttpError extends Error {
    // constructor is optional; you should omit it if you just want a custom error
    // type for inheritance and type checking
    constructor(message = 'Default message', status, originalMessage) {
        super(message);
        this.status = status;
        this.originalMessage = originalMessage;
    }
}
export default { get, post, patch, remove, getQueryUrl };

export async function get(path, headersOrNeedToken = true, hostnameOverride) {
    // Function overload using duck typing.
    const headers = typeof headersOrNeedToken !== 'boolean' ? headersOrNeedToken : {};
    const needToken = typeof headersOrNeedToken === 'boolean' ? headersOrNeedToken : true;

    const params = await setGetMethod(headers, needToken);
    const url = getFullURL(path, hostnameOverride);
    const response = await fetch(url, params);
    if (response.ok) {
        // don't try to deserialize if no content
        switch (response.status) {
            case httpStatus.ACCEPTED: // Accepted - (receive from socket instead)
                logging.warn(
                    `Request to get ${url} timed out. There seems to be some congestion on the line. Please try again.`
                );
                return 'Accepted - No Content';
            case httpStatus.NO_CONTENT: // No content
                return;
            case httpStatus.OK:
                if (isContentOnly(url)) {
                    // assuming our content is a blob because right
                    // now that's the only type we are sending back
                    // for /content urls.
                    return response.blob();
                }
                return response.json();
            default:
                return response.json();
        }
    }
    // No changes
    if (response.status === httpStatus.NOT_MODIFIED) {
        return null;
    }
    let detailMessage = `Request to get ${url} failed with status ${response.status}:${response.statusText}. ${
        response.message || ''
    }`;
    if (!response.ok && response.body) {
        try {
            detailMessage = await response.json();
            detailMessage = detailMessage.message || detailMessage;
        } catch (error) {
            //there probably was no body after all
        }
    }
    throw new HttpError(detailMessage, response.status);
}

/**
 * @param {string} path
 * @param {*} data
 * @param {boolean|object} headersOrNeedToken
 * @param {string} hostnameOverride - The hostname to use instead of the default
 * @returns {Promise<object|'Accepted - No Content'|void>} the object if the server returned something, a string if a 202 was returned, or nothing if 204 was returned
 * @throws {HttpError}
 */
export async function post(path, data, headersOrNeedToken = true, hostnameOverride) {
    // Function overload using duck typing.
    let headers = typeof headersOrNeedToken !== 'boolean' ? headersOrNeedToken : {};
    //we need a token when the header is a boolean AND is is set to true
    let needToken = typeof headersOrNeedToken === 'boolean' ? headersOrNeedToken : true;
    const isFileContent = path.endsWith('/content');

    const params = await setPostMethod(data, headers, isFileContent, needToken);
    const url = getFullURL(getUrlWithoutId(path), hostnameOverride);
    const response = await fetch(url, params);
    return handleNonGetHttpResponse(response, path, 'POST');
}

export async function remove(path, headers) {
    const params = await setDeleteMethod(headers);
    const url = getFullURL(path);
    const response = await fetch(url, params);

    return handleNonGetHttpResponse(response, path, 'remove');
}

/**
 * @param {string} path
 * @param {object} patch
 * @param {object} headers
 * @param {string} hostnameOverride - The hostname to use instead of the default
 * @returns {Promise<object|'Accepted - No Content'|void>} the object if the server returned something, a string if a 202 was returned, or nothing if 204 was returned
 * @throws {HttpError}
 */
export async function patch(path, patch, headers, hostnameOverride) {
    const params = await setPatchMethod(patch, headers);
    const url = getFullURL(path, hostnameOverride);
    const response = await fetch(url, params);

    return handleNonGetHttpResponse(response, path, 'update');
}

/**
 * @param {Response} response The response from the fetch api
 * @param {string} url The endpoint that was requested
 * @param {string} operation - The http method
 * @returns {Promise<object|'Accepted - No Content'|void>} the object if the server returned something, a string if a 202 was returned, or nothing if 204 was returned
 * @throws {HttpError}
 */
export async function handleNonGetHttpResponse(response, url, operation) {
    if (response.ok) {
        // don't try to deserialize if no content
        switch (response.status) {
            case httpStatus.CONFLICT:
                // Treat conflicts like 200s - server will sort them out
                // for us, but API-only users need to receive 409s.
                return response.json();
            case httpStatus.ACCEPTED: // Accepted - (receive from socket instead)
                // log.warn(
                //     `Request to ${operation} ${url} was delayed. There seems to be some congestion on the line, but the request should eventually succeed.`
                // );
                return 'Accepted - No Content';
            case httpStatus.NO_CONTENT: // No content
                return;
            default:
                try {
                    return await response.json();
                } catch {
                    // If response.json() fails, send back the raw response.
                    return response;
                }
        }
    }

    // Response NOT ok.
    let message = `Request to ${operation} ${url} failed with status ${response.status}:${response.statusText}.`;
    try {
        switch (response.status) {
            case httpStatus.PAYMENT_REQUIRED: {
                const payload = await response.json();
                //Payment required already gives us a pretty message. No need to wrap it with anything
                message = payload.message;
                break;
            }
            case httpStatus.BAD_REQUEST: {
                const payload = await response.json();
                if (payload.message != null) {
                    message = payload.message;
                }
                break;
            }
            case httpStatus.CONFLICT: {
                const payload = await response.json();
                if (payload.message != null) {
                    message = payload.message;
                }
                break;
            }
            case httpStatus.UNAUTHORIZED: {
                const payload = await response.json();
                if (payload.message != null) {
                    message = payload.message;
                }
                break;
            }
            case httpStatus.NOT_FOUND: {
                const payload = await response.json();
                if (payload.message != null) {
                    message = payload.message;
                }
                break;
            }
            default:
                break;
        }
    } catch (err) {
        // Probably a failure during response.json() because there is no body for this message
        // from server.
        // Use default message in error below.
    }
    throw new HttpError(message, response.status);
}

export function setPostMethod(data, headers = {}, isFileContent, includeSstHeaders = true) {
    const cleanData = isFileContent ? data : removeMetaData(data);
    let body;
    headers =
        data instanceof FormData
            ? headers
            : {
                  'content-type': 'application/json',
                  ...headers
              };
    if (isFileContent) {
        body = cleanData;
    } else if (headers['content-type'] === 'application/x-www-form-urlencoded') {
        body = typeof cleanData === 'string' ? cleanData : formUrlEncode(cleanData);
    } else {
        body = typeof cleanData === 'string' ? cleanData : JSON.stringify(cleanData);
    }
    return addToken(
        {
            method: 'POST',
            body,
            headers
        },
        includeSstHeaders
    );
}

async function setGetMethod(headers = {}, needToken) {
    let params = {
        method: 'get',
        headers
    };
    const newParams = await addToken(params, needToken);
    return newParams;
}

function setPatchMethod(data, headers = {}) {
    let params = {
        method: 'PATCH',
        body: JSON.stringify(data) || '[]',
        headers: {
            'content-type': 'application/json',
            ...headers
        }
    };
    return addToken(params, true);
}

function setDeleteMethod(headers = {}) {
    let params = {
        method: 'DELETE',
        headers: {
            ...headers
        }
    };
    return addToken(params, true);
}

function getUrlWithoutId(path) {
    let id = path.substr(path.lastIndexOf('/') + 1);
    if (id.length === 24) {
        return path.substr(0, path.lastIndexOf('/'));
    } else {
        return path;
    }
}

async function addToken(params, includeSstHeaders) {
    const token = await getToken();
    if (includeSstHeaders) {
        const { tenant, useCase } = await getActiveUseCaseAndTenant();
        const tenantId = tenant['identity:tenant']._id;
        const useCaseId = useCase['metaui:useCase']._id;
        if (tenantId) {
            params.headers = { ...params.headers, 'sst-active-tenant-id': tenantId, 'sst-app-id': useCaseId };
        }
    }
    params.headers = { ...params.headers, Authorization: token };
    return params;
}

/**
 * We are assuming a last node of 'content' means this result is only content.
 * @param {string} url - the request url
 */
function isContentOnly(url) {
    const pathParts = url.split('/');
    return pathParts[pathParts.length - 1] === 'content';
}

function formUrlEncode(data) {
    let formBody = [];
    for (const property in data) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
}
function removeMetaData(data) {
    let cleanData = { ...data };
    if (data.$loki) delete cleanData.$loki;
    if (data._idx) delete cleanData._idx;
    if (data.meta) delete cleanData.meta;
    if (data.downloaded) delete cleanData.downloaded;
    //never send an rmaNo to the server:
    if (data.rmaNo) delete cleanData.rmaNo;
    return cleanData;
}
