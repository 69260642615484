import { useEffect, useState } from 'react';
import useEventSink from '../../hooks/useEventSink';
import useActiveRecord from '../../hooks/useActiveRecord';
const emptyErrors = { field: {}, form: [] };
/**
 * Only used for `type: offlinePin`, as extracted from `useActiveRecord` below.
 */
export default function useAuthenticationType() {
    const [subscribe, publish] = useEventSink();
    const [errors, setErrors] = useState(emptyErrors);
    const { record, type } = useActiveRecord() ?? {};

    // Create an active security profile record for use by the OfflinePin component.
    useEffect(() => {
        if (!record) {
            setTimeout(() => {
                publish({}, { verb: 'new', namespace: 'security', relation: 'profile', type });
            }, 1);
        }
    }, [publish, record, type]);

    // Capture any failures and set the errors state.
    useEffect(() => {
        const unsubscribes = [
            subscribe(
                {
                    verb: 'create',
                    namespace: 'security',
                    relation: 'profile',
                    type,
                    status: 'failure'
                },
                payload => {
                    if (payload.errors) {
                        setErrors(payload.errors);
                    }
                }
            ),
            subscribe(
                {
                    verb: 'submit',
                    namespace: 'security',
                    relation: 'profile',
                    type,
                    status: 'failure'
                },
                payload => {
                    if (payload.errors) {
                        setErrors(payload.errors);
                    }
                }
            ),
            subscribe(
                {
                    verb: 'validate',
                    namespace: 'security',
                    relation: 'profile',
                    type,
                    status: 'failure'
                },
                payload => {
                    if (payload.errors) {
                        setErrors(payload.errors);
                    }
                }
            )
        ];
        return () => unsubscribes.forEach(u => u());
    }, [subscribe, type]);
    return errors;
}
