import { applyMiddleware, compose, createStore } from 'redux';
import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import detectNetwork from '@redux-offline/redux-offline/lib/defaults/detectNetwork';
import offlineEffect from './offlineEffectOverride';
import discard from './discardOverride';
import retry from './retryOverride';
import defaultCommitRollbackMiddleware from './defaultCommitRollbackMiddleware';
import observeStatusMiddleware from './observeStatusMiddleware';
import _storage from './reduxOfflineStorage';
import logging from '@sstdev/lib_logging';

// this configuration relies on redux-persist v4.5.0.
// V5 and higher have a breaking change, and you'd have to incorporate a "persistReducer"?
// https://gist.github.com/jarvisluong/f14872b9c7ed00bc2afc89c4622e3b55
import { persistStore } from 'redux-persist';

import offlineQueue from './offlineQueueOverride';

/**
 * @type Store
 * @property {function():object)} getState get the state
 * @property {function(action:object):void} dispatch dispatch an action into the redux flow
 */
let _store; //redux store;
/**
 * @type Persistor
 * @property {function():void)} purge purges the state
 */
let _persistor; // redux persist store.

// This is how you setup Redux tools.
const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...

              // shouldHotReload needs to be false because we add reducers dynamically and that will cause actions to be called over and over again
              // if hot reload is enabled.  See https://github.com/reduxjs/redux-devtools/issues/304
              shouldHotReload: false
          })
        : compose;

const initialState = {};

/**
 *
 * @returns {{
 *    getState:function():object;
 *    dispatch: function({type:string, payload:object}):void;
 *    purge: function():void;
 * }}
 */
export default async function configureStore(eventSink, testOfflineConfig, testStoreWrapper) {
    let promises = [];
    let overrideEnabledState;
    promises.push(
        new Promise((resolve, reject) => {
            const stateRehydratedCallback = function (err) {
                if (err) {
                    reject(err);
                }
                resolve();
            };
            _store = createStore(
                defaultReducer,
                initialState,
                composeEnhancers(
                    offline({
                        ...offlineConfig,
                        retry,
                        ...testOfflineConfig, // only set during tests.
                        detectNetwork: callback => {
                            overrideEnabledState = enable => callback({ online: enable });
                            detectNetwork.default(callback);
                        },
                        effect: offlineEffect(eventSink),
                        discard,
                        queue: offlineQueue(eventSink),
                        persistCallback: stateRehydratedCallback,
                        persist: (store, persistOptions, persistCallback) => {
                            persistOptions = {
                                ...persistOptions,
                                storage: _storage,
                                blacklist: [
                                    'ajaxStatus',
                                    'application_progress',
                                    'application_runtime',
                                    'application_runtimeComponent',
                                    'hnode_aliases',
                                    'login',
                                    'metaui_useCase'
                                ]
                            };
                            // For tests - ensure the store has state.
                            if (testStoreWrapper) {
                                _store = testStoreWrapper(store);
                            }
                            _persistor = persistStore(store, persistOptions, persistCallback);
                        }
                    }),
                    applyMiddleware(defaultCommitRollbackMiddleware(eventSink)),
                    applyMiddleware(observeStatusMiddleware(eventSink))
                )
            );
        })
    );
    return Promise.all(promises).then(() => {
        // getStore.init(_store);
        // purge.init(_persistor);
        // syncOnNetworkOffline.init(_store);
        return { ..._persistor, ..._store, enable: overrideEnabledState };
    });
}

function defaultReducer(state = initialState, action) {
    switch (action.type) {
        // case '':
        //     //todo, merge action into state
        //     return state;
        default:
            logging.debug(`${action.type} not handled by offline reducer after redux-offline`);
            return state;
    }
}
