/**
 * @file SetPin.js
 * This is a very simple form that allows the user to set a PIN for offline access.
 * It just includes the PIN field, a PIN confirmation field, and a submit button.
 */
import { useContext, createElement as rc, useRef, useCallback, useEffect } from 'react';
import { h2, Card, contexts, styled, fromTheme, View, webOnlyStyles, nativeOnlyStyles, Text } from 'lib_ui-primitives';
import LoadingBoundary from './contextProviders/LoadingBoundary';
import { nativeOnlyProperties, globalConfig } from 'lib_ui-services';
import PasswordWithConfirm from './formElement/PasswordWithConfirm';
import Form from './form/Form';
import FormButton from './formButton/FormButton';
import useEventSink from './../hooks/useEventSink';
import { CardBox } from './Unauthenticated/styles';
import { setupRulesEngine } from 'lib_ui-core';
import ActiveRecord from './contextProviders/ActiveRecord';

const { SessionContext } = contexts;
const EMPTY_SESSION = {};

// Hardcoded metadata for the form.
let hNode = {
    id: 'setPinForm',
    title: '',
    hNodeType: 'Form',
    hNodeTypeGroup: 'form',
    namespace: 'security',
    relation: 'profile',
    type: 'setOfflinePin',
    forAction: 'new'
};

const ExplanationText = styled(Text).attrs({ name: 'explanation-text' })`
    margin: ${fromTheme('textMargin')};
    text-align: center;
`;
ExplanationText.displayName = 'ExplanationText';

const HeaderText = styled(h2)`
    color: ${fromTheme('card', 'header', 'textColor')};
    margin: ${({ theme }) => (theme.mobile ? '0px' : theme.viewMarginMore + 'px')};
    padding: ${fromTheme('viewPadding')};
    text-align: center;
`;

// Do not center horizontally on mobile or the keyboard will cut off the bottom.
let Centered = styled(View).attrs({ name: 'centered' })`
    min-height: ${fromTheme('height')};
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: ${({ theme: { mobile } }) => (mobile ? 'space-between' : 'center')};
`;

Centered = webOnlyStyles(Centered)`
    box-sizing: border-box;
`;
// Do not constrain the width on mobile view or the login title will have spaces
// at the edge.  Do not use 'unset' as this will break react native.
let Main = styled(View).attrs({ name: 'main' })`
    flex-direction: column;
    justify-content: center;
    flex-grow: 0;
    max-width: ${({ theme: { mobile } }) => (mobile ? '9999px' : '370px')};
`;
// Prevents the credential area from getting squished on native.
Main = nativeOnlyStyles(Main)`
    flex-shrink: 0;
`;
Main.displayName = 'Main';

export default function SetPin(props) {
    // `record` is mainly included for test purposes here. It will usually
    // be supplied to the ActiveRecord component by an event from the rules
    // engine or similar.
    const { record, currentRoute, children } = props ?? {};
    const { namespace, relation, forAction, type } = hNode;

    // Create the active record context so the default form component will work.
    // prettier-ignore
    return rc(ActiveRecord, { namespace, relation, type, record, activationVerb: forAction, currentRoute },
        rc(InnerSetPin, props, children)
    );
}

/**
 * Both for new users, and for users who used forgot Password and clicked the link in the email
 */
export function InnerSetPin() {
    const pinEntryRef = useRef();
    const eventSink = useEventSink();
    const [subscribe, publish] = eventSink;
    const session = useContext(SessionContext) || EMPTY_SESSION;
    const { profile } = session;

    // The default form component needs the rules engine.
    useEffect(() => {
        setupRulesEngine(profile, eventSink);
    }, [eventSink, profile]);

    // Create a new empty active record for the form.
    useEffect(() => {
        publish({}, { verb: 'new', namespace: 'security', relation: 'profile', type: 'setOfflinePin' });
    }, [publish]);

    // Subscribe to errors and notify user.
    useEffect(() => {
        return subscribe(
            { verb: 'submit', namespace: 'security', relation: 'profile', status: 'failure' },
            ({ errors }) => {
                publish(
                    {
                        isError: true,
                        message: errors.form[0].message,
                        timeout: globalConfig().notificationTimeout
                    },
                    { verb: 'pop', namespace: 'application', relation: 'notification' }
                );
                pinEntryRef.current.focus();
            }
        );
    }, [subscribe, publish]);

    // Allow user to submit the form or focus on an unfilled PIN field by pressing Enter.
    const onKeyPress = useCallback(
        e => {
            if (e.key === 'Enter') {
                if (pinEntryRef.current.value == null || pinEntryRef.current.value.length === 0) {
                    pinEntryRef.current.focus();
                } else {
                    publish({}, { verb: 'submit', namespace: 'security', relation: 'profile', type: 'setOfflinePin' });
                }
            }
        },
        [publish]
    );

    // prettier-ignore
    return rc(Centered, null,
        rc(Main, null,
            rc(LoadingBoundary, null,
                rc(CardBox, null,
                    rc(Card.Header,
                        { style: { justifyContent: 'center' } },
                        rc(HeaderText, { name: 'card-header-title', style: { margin: 0 } }, 'Set your PIN for offline access')
                    ),
                    rc(Card.Body, null,
                        rc(Form, { hNode, popErrorNotifications: false, heightGreedy: false, centerFields: true },
                            rc(PasswordWithConfirm, {
                                ref: pinEntryRef,
                                onKeyPress,
                                ...nativeOnlyProperties({
                                    onChange: text => pinEntryRef.current.value = text
                                }),
                                allowPasswordFill: true,
                                hNode: {
                                    id: 'set-pin',
                                    title: 'Offline PIN',
                                    hNodeType: 'PasswordWithConfirm',
                                    hNodeTypeGroup: 'formElement',
                                    propertyName: 'offlinePin',
                                    defaultValue: '',
                                    treePosition: { sequence: 2, minSequence: 3, maxSequence: 5 }
                                }
                            }),
                            rc(FormButton, {
                                hNode: {
                                    id: 'setPinButton',
                                    title: 'SET PIN',
                                    hNodeType: 'FormButton',
                                    hNodeBlockGroup: 'formButtons',
                                    hNodeTypeGroup: 'formButton',
                                    formAction: 'submit'
                                }
                            })
                        )
                    ),
                    rc(Card.Footer, null,
                        rc(ExplanationText, null, 'For increased security, a PIN will be used to access this application when you are offline.')
                    )
                )
            )
        )
    );
}
