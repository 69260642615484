import logging from '@sstdev/lib_logging';
import { add, startOfDay, endOfDay } from 'date-fns';
import nativeOnlyProperties from '../nativeOnlyProperties';
import getHostName, { overrideHostname as _overrideHostname, asyncHostname } from './getHostname';
import getBrand from './getBrand';
import getEnvironment from './getEnvironment';
import isDeployed from './isDeployed';
import getPort from './getPort';
import getProtocol from './getProtocol';
import timeout from './timeouts';
import getGlobalObject from './getGlobalObject';
import getAuth0Config from './getAuth0Config';
const global = getGlobalObject();
/**
 * This is read only after it is initialized to prevent accidental updates of
 * global config, but it can be intentionally reinitialized.  See overrideHostname()
 * below.
 */

// productionBuild just means webpack built it for production (minified, etc.)
// - it could still be running in dev, staging, etc.
const productionBuild = process.env.NODE_ENV === 'production';
//const productionBuild = true; // Testing with dev url.

let globalConfig;
let initialized = false;
/**
 * @type {number} GroupNumber - default to 0 (mainly for react native).
 */
let _groupNumber = 0;
/**
 * @type {Array<string>} FeatureFlags
 */
let _featureFlags = [];

/**
 * @type {{
 *  maxShortLength:number,
 *  shortStrategy:string,
 *  longStrategy:string,
 *  displayStrategy:string
 * } | undefined} RfidConfig
 */
let _rfid;

function initialize() {
    const hostname = getHostName(productionBuild);
    const environment = getEnvironment(productionBuild);
    const port = getPort(productionBuild);
    const protocol = getProtocol(productionBuild);
    const protocolSocket = getProtocol(productionBuild, 'ws');
    const brand = getBrand();
    const auth0Config = getAuth0Config(brand);

    let config = {
        actionElementDebounceMilliseconds: 100,
        auth0: {
            AUTH0_CLIENT_ID: auth0Config.AUTH0_CLIENT_ID,
            AUTH0_DOMAIN: auth0Config.AUTH0_DOMAIN,
            AUTH0_CONNECTION_NAME: auth0Config.AUTH0_CONNECTION_NAME
        },
        auth0RefreshTokenAbsoluteLifetimeDays: 30,
        backgroundSyncBatchSize: 1000,
        bluetooth: {
            scanPeriod: 5000
        },
        brand,
        defaultDateRange: {
            endDateTime: endOfDay(new Date()),
            startDateTime: startOfDay(add(new Date(), { months: -3 }))
        },
        defaultMetaFields: ['_id'],
        environment,
        exportTypes: [
            {
                _id: '1',
                bookType: 'xlsx',
                description: 'Excel 2007+ XML (XLSX)',
                fileExt: '.xlsx'
            },
            {
                _id: '2',
                bookType: 'biff8',
                description: 'Excel 97-2004 Workbook (XLS)',
                fileExt: '.xls'
            },
            {
                _id: '3',
                bookType: 'csv',
                description: 'Comma Separated Values (CSV)',
                fileExt: '.csv'
            },
            {
                _id: '4',
                bookType: 'html',
                description: 'HTML Document',
                fileExt: '.html'
            }
        ],
        featureFlags: _featureFlags,
        fileTypes: { image: 'image' },
        getRecordsDebounceMilliseconds: 100,
        getRecordsThrottleMilliseconds: 3000,
        groupNumber: _groupNumber,
        hostname,
        isDeployed: isDeployed(productionBuild),
        loadingTimeout: global.LOADING_TIMEOUT ?? 15000,
        logRelease: 'silo_ui-web@' + __PACKAGE_VERSION__, // eslint-disable-line no-undef
        // 0: Error, 1: Warning, 2: Info (default), 3: Debug
        logType: productionBuild ? logging.outputType.CONSOLE + logging.outputType.SENTRY : logging.outputType.CONSOLE,
        maxRecordsInLimitedSizeCollection: 50000,
        maxSecondsOfflineWithoutResync: 30,
        minFullTextSearchTermLength: 3,
        minLogLevel: productionBuild ? 1 : 3,
        networkErrorRetryMilliseconds: 4000,
        notificationTimeout: 5000,
        notificationTimeoutFormError: 10000,
        offlineSessionExpirationDays: 60,
        onlineSearchDebounceMilliseconds: 1000,
        pageSizeForLimitSyncSizeRelations: 200,
        port,
        prohibitedPreviewVerbs: ['cancel'],
        protocol,
        protocolSocket,
        rfid: _rfid,
        searchDebounceMilliseconds: 250,
        sensorScanIntervalMilliseconds: 1000,
        socketEndpointLastNode: 'socket.iov3', // v1 & v2 = 'socket.io',
        // Going to start grouping timeouts in here
        timeout,
        useOauthAuthentication: false,
        useTempSecurityEndpoint: false,
        validActionResults: ['FAILURE', 'SUCCESS', 'UNNECESSARY', 'COMMIT', 'ROLLBACK'],
        validActionVerbs: [
            'cancel',
            'change',
            'clear',
            'clearSelection',
            'close',
            'confirm',
            'copy',
            'edit',
            'export',
            'filter',
            // not for database or http data access (use load for those) - for acquiring a resource or value from another part of the application
            'get',
            'import',
            'login',
            'logout',
            'load',
            'mark',
            'new',
            'notify',
            'open',
            'persist',
            'pop',
            'purge',
            'refresh',
            'register',
            'remove',
            'reset',
            'resize',
            'save',
            'select',
            'update',
            'setFilter',
            'startup',
            'stop',
            'submit',
            'upsert',
            'validate',
            'view'
        ],
        validHeaderProperties: [
            'modifiedBy',
            'modifiedTime',
            'createdBy',
            'createdTime',
            'deletedBy',
            'deletedTime',
            'conflict.status',
            'conflict.reason',
            'conflict.winnerId',
            'correlationId'
        ]
    };

    config = {
        ...config,
        ...nativeOnlyProperties({
            host: `${hostname}${isDeployed() ? '' : ':'}${port}`,
            logRelease: 'siloUiNative@' + __PACKAGE_VERSION__, // eslint-disable-line no-undef
            logType: productionBuild
                ? logging.outputType.BRIDGE
                : logging.outputType.CONSOLE + logging.outputType.BRIDGE,
            requiresSdStorage: true,
            requiresBluetooth: true,
            bluetooth: {
                scanPeriod: 5000
            },
            maxRecordsInLimitedSizeCollection: 20000
        })
    };

    globalConfig = config;
    // Make available outside lib_ui-services
    globalConfig.overrideHostname = overrideHostname;
    globalConfig.setGroupNumber = setGroupNumber;
    globalConfig.asyncHostname = asyncHostname;
    globalConfig.setFeatureFlags = setFeatureFlags;
    globalConfig.setRfidConfig = setRfidConfig;
    Object.freeze(globalConfig);

    initialized = true;
}

function getGlobalConfig() {
    if (!initialized) {
        initialize();
    }
    return globalConfig;
}

export default getGlobalConfig;
export function reInitialize() {
    initialized = false;
}
export function overrideHostname(hostname) {
    _overrideHostname(hostname);
    reInitialize();
}
export function setGroupNumber(groupNumber) {
    _groupNumber = groupNumber;
    reInitialize();
}

export function setFeatureFlags(featureFlags) {
    _featureFlags = featureFlags;
    reInitialize();
}
export function setRfidConfig(rfidConfig) {
    _rfid = rfidConfig;
    reInitialize();
}
