import globalConfig from './globalConfig';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { handleNonGetHttpResponse, setPostMethod } from './http';
import logging from '@sstdev/lib_logging';
import getRouter from './getRouter';

/**
 * This is consumed primarily by the ./session service.
 */

/**
 * @typedef {import('@auth0/auth0-spa-js').Auth0Client} Auth0Client
 */

/**
 * @type {{
 *   createAuth0Client: typeof import('@auth0/auth0-spa-js').createAuth0Client,
 *   globalConfig: typeof import('./globalConfig').default,
 *   clearAuth0Connection: () => void,
 *   fetch: typeof fetch,
 *   handleNonGetHttpResponse: typeof import('./http').handleNonGetHttpResponse,
 *   setPostMethod: typeof import('./http').setPostMethod,
 *   hasAuthParams: typeof hasAuthParams,
 *   getRouter: typeof import('./getRouter').default,
 *   auth0Connection?: Auth0Client
 * }}
 */
let _p = {
    createAuth0Client,
    globalConfig,
    clearAuth0Connection: () => delete _p.auth0Connection,
    fetch,
    handleNonGetHttpResponse,
    setPostMethod,
    hasAuthParams,
    getRouter
};
export const _private = _p;

/**
 * This creates an Auth0 connection with the given parameters.
 * If the connection already exists, it is returned.
 * @param {string} protocol - e.g. 'https:'
 * @param {string} host - e.g. 'localhost:8888'
 * @param {string} groupPath - portion of the URL path that identifies the group
 * @returns {Promise<import('@auth0/auth0-spa-js').Auth0Client>}
 *
 * @example
 * const connection = await getConnection('https:', 'dev.redbeam.com', '/g/0');
 */
export async function init(protocol, host, groupPath) {
    if (_p.auth0Connection != null) {
        return _p.auth0Connection;
    }
    if (protocol == null || host == null || groupPath == null) {
        throw new Error('getConnection requires protocol, host, and groupPath (at least on the first call)');
    }
    const { auth0 } = _p.globalConfig();
    const audience = `${protocol}//${host}/api`;
    const redirect_uri = `${protocol}//${host}${groupPath}/`;
    // Login and get user info
    _p.auth0Connection = await _p.createAuth0Client({
        domain: auth0.AUTH0_DOMAIN,
        clientId: auth0.AUTH0_CLIENT_ID,
        authorizationParams: {
            audience,
            redirect_uri
        },
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        // Copied to the /dist path (served by the node server) from node_modules during webpack build.
        workerUrl: `${groupPath}/auth0-spa-js.worker.production.js`
    });
    return _p.auth0Connection;
}

export async function changePassword(email) {
    // The auth0-spa-js library does not support changing passwords.
    // This is supported by the auth0.js library, but I didn't want to include a whole new
    // library just for this one function.

    /*
    POST https://{yourDomain}/dbconnections/change_password
    Content-Type: application/json
    {
        "client_id": "{yourClientId}",
        "email": "EMAIL",
        "connection": "CONNECTION",
        "organization": "ORGANIZATION_ID"
    }
    */
    const domain = _p.globalConfig().auth0.AUTH0_DOMAIN;
    const data = {
        client_id: _p.globalConfig().auth0.AUTH0_CLIENT_ID,
        email,
        connection: _p.globalConfig().auth0.AUTH0_CONNECTION_NAME
    };
    const url = `https://${domain}/dbconnections/change_password`;
    const params = await _p.setPostMethod(data, {}, false, false);
    const response = await _p.fetch(url, params);
    return _p.handleNonGetHttpResponse(response, url, 'POST');
}

/**
 * Handles the login process using Auth0.
 *
 * This function checks if authentication parameters are present. If they are,
 * it handles the redirect callback from Auth0 and navigates to the appropriate
 * location based on the app state. If authentication parameters are not present,
 * it logs the navigation attempt and redirects the user to the Auth0 login page.
 *
 * @async
 * @function login
 * @returns {Promise<boolean>} A promise that resolves when the login process is complete.
 * Returns true if the login will redirect to the login domain.
 */
export async function login() {
    const { goToLocation, getRoot, protocol, host } = _p.getRouter();
    if (_p.hasAuthParams()) {
        const result = await _p.auth0Connection.handleRedirectCallback();
        if (result.appState?.returnTo) {
            goToLocation(result.appState?.returnTo);
        } else {
            goToLocation(`${getRoot()}`);
        }
    } else {
        // FYI - The group path here is based off of the request URL.
        // It is possible that this will be a different group than that requested for
        // the tenant and the Backbone.js component will redirect if that is the case.
        logging.info(
            `[SESSION][NAVIGATION] Going to auth0 login page with login success redirecting to ${protocol}//${host}${getRoot()}.`
        );
        await _p.auth0Connection.loginWithRedirect({
            redirect_uri: `${protocol}//${host}${getRoot()}`
        });
        return true;
    }
}
const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;
function hasAuthParams(searchParams = window.location.search) {
    return (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) && STATE_RE.test(searchParams);
}

export async function isAuthenticated() {
    return _p.auth0Connection.isAuthenticated();
}

export async function getTokenSilently() {
    const token = _p.auth0Connection.getTokenSilently();
    // It seems as though there is some inconsistency in the return type of this function.
    // I'm not sure if it is different for different versions of the Auth0 library?
    return token.access_token ?? token;
}

export async function getUser() {
    return _p.auth0Connection.getUser();
}

export async function logout(shouldRedirect = true, redirectUrl) {
    if (shouldRedirect) {
        if (redirectUrl == null) {
            if (_p.auth0Connection.options.authorizationParams.redirect_uri == null) {
                throw new Error(
                    'redirect_uri was not set in the Auth0 connection options (see init method of lib_ui-services/auth0.js).'
                );
            }
            // Redirect to default redirect url (set up when instantiating the Auth0 connection).
            return _p.auth0Connection.logout({
                logoutParams: {
                    returnTo: _p.auth0Connection.options.authorizationParams.redirect_uri
                }
            });
        }
        return _p.auth0Connection.logout({ logoutParams: { returnTo: redirectUrl } });
    } else {
        // This will not redirect the user to the Auth0 login page.  By itself, this won't
        // result in much happening, but session/index.js which is probably calling this
        // when the user is offline will also remove session information - resulting in the
        // user being sent to the pin login page.
        return _p.auth0Connection.logout({ openUrl: () => Promise.resolve() });
    }
    // Using "federated" will also logout from the identity (aka OpenID in this case) provider (e.g. Google or Microsoft).
    // We may need to create a setting per tenant that dictates whether this should happen or not.
    // Many times a device is for shared use, so we would want to logout if they used an OpenID
    // provider to login, but typically (for a non-shared device) this is not what the user desires
    // because it would log them out of everything they are using that OpenID account for.
    // return _p.auth0Connection.logout({ logoutParams: { federated: true } });
}
