import { createElement as rc, Fragment } from 'react';
import { View, Button, fromTheme, styled } from 'lib_ui-primitives';
import { metadata } from 'lib_ui-services';
import HNode from '../../../HNode';
import lodash from 'lodash';
const { memoize } = lodash;
import FilterInterdependencyBoundary from '../../contextProviders/FilterInterdependencyBoundary';
import createTreePositionGetter from '../../../utilities/createTreePositionGetter';
import PropTypes from 'prop-types';
import HNodeTreePosition from '../../../HNodeTreePosition';
import logging from '@sstdev/lib_logging';
import makeCollapsibleRow from '../../helpers/makeCollapsibleRow';

const ListRow = styled(View).attrs({ name: 'list-row' })`
    background-color: ${fromTheme('backgroundColor')};
    margin-left: ${fromTheme('viewMarginMore')};
    margin-right: ${fromTheme('viewMargin')};
    margin-bottom: ${fromTheme('viewMargin')};
    padding: ${fromTheme('viewPadding')};
    flex-direction: row;
    align-items: flex-end;
`;
const ListSubForm = styled(View).attrs({ displayName: 'list-subform' })`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

// Try to avoid a re-render of the children by memoizing the child HNode
export const getChildHNode = memoize(
    (childHNode, parentPropertyPath, propertyName, rowHierarchy, propertyIndex) => {
        const key = [
            childHNode.id,
            parentPropertyPath,
            propertyName,
            childHNode.propertyName,
            propertyIndex,
            rowHierarchy
        ].join('_');
        logging.debug('[EditableListRow] getChildHNode key: ', key);
        // propertyPath gets longer as the children get deeper
        const propertyPath = `${metadata.getPathToProperty({
            propertyPath: parentPropertyPath,
            propertyName
        })}[${propertyIndex}]`;
        return { ...childHNode, propertyPath, id: `${childHNode.id}_${rowHierarchy}` };
    },
    (childHNode, parentPropertyPath, propertyName, rowHierarchy, propertyIndex) => {
        const key = [
            childHNode.id,
            parentPropertyPath,
            propertyName,
            childHNode.propertyName,
            propertyIndex,
            rowHierarchy
        ].join('_');
        return key;
    }
);

const EditableListRow = makeCollapsibleRow(function EditableListRow(props, { rowCoordinates }) {
    const {
        disabled,
        childrenHNodes,
        remove,
        propertyPath: parentPropertyPath,
        propertyName,
        currentRoute,
        treePosition,
        prettyName,
        propertyIndex
    } = props ?? {};

    function subFormRemove() {
        remove(propertyIndex);
    }
    const rowHierarchy = rowCoordinates.join('-');
    const getTreePosition = createTreePositionGetter(treePosition, childrenHNodes.length);
    // prettier-ignore
    return rc(FilterInterdependencyBoundary, { name: `editableListRow_${rowHierarchy}` },
        rc(ListRow, {'data-row-coordinates' : rowHierarchy},
            rc(Fragment, null,
                rc(ListSubForm, null,
                    childrenHNodes.map((childHNode, index) => {
                        const hNode = getChildHNode(childHNode, parentPropertyPath, propertyName, rowHierarchy, propertyIndex);
                        hNode.treePosition = getTreePosition(index);
                        return rc(HNode, { hNode, key: index, currentRoute });
                    })
                ),
                rc(Button, { title: `Delete ${prettyName}`, disabled, icon: 'clear', buttonStyle: 'round', color: 'error', onClick: subFormRemove }),
                /* eslint-disable-next-line no-undef */
                __DEBUG_HNODE_TREE_POSITION__ && rc(HNodeTreePosition, { treePosition })
            )
        )
    );
});
EditableListRow.propTypes = {
    index: PropTypes.number.isRequired,
    treePosition: PropTypes.shape({
        sequence: PropTypes.number.isRequired
    }).isRequired
};

export default EditableListRow;
