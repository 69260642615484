import { createElement as rc, useState, Fragment, useContext, useCallback } from 'react';
import useEventSink from '../../hooks/useEventSink';
import { ThemeContext } from 'styled-components';
import { Button, Modal, Card } from 'lib_ui-primitives';
import useDbView from '../../hooks/useDbView';
import SelectedNotification from './SelectedNotification';
import NotificationList from './NotificationList';
import { Title, TopButtonBar, FullScreenBody, ClearAll, NoNotifications } from './styles';
import isEmpty from 'lodash/isEmpty';

const _p = {
    useDbView,
    useEventSink,
    NotificationList
};
export const _private = _p;

const virtualhNode = {
    id: 'notificationIcon'
};

const NotificationIcon = ({ id }) => {
    const [selectedNotification, setSelectedNotification] = useState();
    const [, publish] = _p.useEventSink();
    const [modalVisible, setModalVisible] = useState();

    const theme = useContext(ThemeContext);
    const { records, recordCount, viewCriteria } = _p.useDbView('application', 'notification', undefined, virtualhNode);
    const notifications = records.filter(n => !isEmpty(n.message));
    viewCriteria.addSort('meta.createdTime', true, virtualhNode.id);
    viewCriteria.addFind({ 'meta.deleted': { $exists: false } });
    const notificationsExist = recordCount > 0;
    const unseen = notifications.some(n => n.seen !== true);

    const onClick = useCallback(
        function onClick() {
            setModalVisible(visible => {
                if (!visible) {
                    // Letting the rules engine know we are looking at notifications so that they can
                    // all be set to 'seen': true.
                    publish({}, { verb: 'view', namespace: 'application', relation: 'notification' });
                }
                return !visible;
            });
        },
        [publish]
    );

    function resetNotifications() {
        publish(
            {},
            {
                verb: 'reset',
                namespace: 'application',
                relation: 'notification'
            }
        );
    }

    const color = unseen ? 'error' : notificationsExist ? 'primary' : 'gray';

    function onShowSelectedNotification(item) {
        setSelectedNotification(item);
    }

    const onRemoveClick = e => {
        e.stopPropagation();
        publish(
            { _id: selectedNotification._id },
            {
                verb: 'remove',
                namespace: 'application',
                relation: 'notification'
            }
        );
    };
    if (selectedNotification) {
        return rc(SelectedNotification, {
            modalVisible,
            theme,
            onRemoveClick,
            selectedNotification,
            setSelectedNotification
        });
    }

    // prettier-ignore
    return rc(Fragment, {},
        rc(Button, { id, name: 'notifications-button', icon: 'notifications', buttonStyle: 'round', onClick, color }),
        rc(Modal, { id: `notificationList-${id}`, scrollingRequired: true, visible: modalVisible },
            rc(Card, { shadow: false },
                rc(TopButtonBar, null,
                    rc(Title, null, 'Notifications'),
                    rc(Button, { buttonStyle: 'round', icon: 'close', onClick, color: 'transparent', fontColor: 'white' })
                ),
                rc(FullScreenBody, null,
                    notificationsExist && rc(_p.NotificationList, { notifications, onShowSelectedNotification }),
                    !notificationsExist && rc(NoNotifications, null, 'No current notifications.')
                ),
                notificationsExist && rc(Card.Footer, null,
                    rc(ClearAll, { buttonStyle: 'primary', title: 'Clear All', value: 'Clear All', onClick: resetNotifications })
                )
            )
        )
    );
};

export default NotificationIcon;
