import { memo, createElement as rc } from 'react';
import PropTypes from 'prop-types';
import useClamp from '../hooks/useClamp';
import styled from '../styled';
import View from './View';
import fromTheme from '../fromTheme';

const ProgressBarGroup = styled(View)`
    margin: 5px 0;
    width: 100%;
`;

const ProgressBarLabel = styled('label')`
    display: inline-block;
    margin-bottom: 0.25rem;
    color: ${fromTheme('defaultFontColor')};
    padding-right: ${fromTheme('textPadding')};
`;

const StyledProgressBar = styled('div')`
    position: relative;
    height: 20px;
    width: 100%;
    border-radius: 50px;
    border: 1px solid #333;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const Bar = styled('div')`
    background-color: ${({ complete }) => (complete ? '#009f53' : '#06a7e2')};
    width: ${({ clampedValue }) => `${clampedValue}%`};
    height: 100%;
    border-radius: inherit;
    transition: all 100ms ease-out;
    text-align: center;
    color: white;
    font-size: 9pt;
`;
const LittleProgress = styled('div')`
    padding-left: ${fromTheme('textPadding')};
    color: ${fromTheme('defaultFontColor')};
    font-size: 9pt;
`;

/**
 * A component that displays a linear progress graph
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {string} props.id
 * @param {number} [props.progress=0] a percentage between 0 and 100
 * @param {string} [props.title]
 */

const ProgressBar = ({ className, id, progress = 0, title }) => {
    const _progress = Math.ceil(progress);
    const clamp = useClamp(0, 100);
    const clampedValue = clamp(_progress);
    const popOutValue = clampedValue < 35;
    // prettier-ignore
    const basicProgressBar = rc(StyledProgressBar, {id},
        rc(Bar, {complete: clampedValue === 100, clampedValue, className},
            popOutValue?' ':`${clampedValue}%`
        ),
        popOutValue && rc(LittleProgress, null, `${clampedValue}%`)
    );
    if (!title) return basicProgressBar;
    // prettier-ignore
    return rc(ProgressBarGroup, null,
        rc(ProgressBarLabel, {htmlFor: id}, title),
        basicProgressBar
    );
};

ProgressBar.propTypes = {
    className: PropTypes.string,
    progress: PropTypes.number,
    title: PropTypes.string
};
export default memo(ProgressBar);
