import { constants } from 'lib_ui-services';
import { hooks } from 'lib_ui-primitives';
const { useBbState: bbState } = hooks;
export default {
    verb: 'didCreate',
    namespace: 'item',
    relation: 'transaction',
    description: 'increment pallet counter',
    priority: 10,
    useCaseIds: [constants.useCaseIds.FREIGHT_RUNNER],

    //this is the actual logic:
    logic: didCreate
};

async function didCreate({ context }) {
    const retention = {
        id: context.session.profile.userId,
        scope: constants.retention.SCOPE.LOCAL,
        visibility: constants.retention.VISIBILITY.PER_BROWSER,
        duration: constants.retention.DURATION.ALWAYS
    };
    let previousCounter = (await bbState.getDirect('palletCounter', retention)) || 0;

    await bbState.setDirect(previousCounter + 1, 'palletCounter', retention);
    const firstScan = await bbState.getDirect('firstScan', retention);
    if (!firstScan) {
        await bbState.setDirect(new Date(), 'firstScan', retention);
    }
}
