import logging from '@sstdev/lib_logging';

let worker = null;
/**
 * Initializes a web worker for handling file export tasks.
 * If the worker is already initialized, the existing instance is returned.
 * When running unit tests, this function will not initialize a worker.
 * @returns {Worker|null} The initialized web worker or null if an error occurs (or during unit tests).
 */
function initializeWorker() {
    if (worker === null) {
        try {
            // eslint-disable-next-line no-undef
            if (__UNIT_TESTING__) return null;
            worker = new Worker(new URL('exportWorker.js', import.meta.url));
        } catch (error) {
            logging.error('Error initializing web worker:', error);
            return null;
        }
    }
    return worker;
}

/**
 * Terminates the currently active web worker and sets its reference to null.
 * If no worker is active, the function does nothing.
 */
function terminateWorker() {
    if (worker !== null) {
        worker.terminate();
        worker = null;
    }
}

/**
 * Downloads a file asynchronously based on the provided parameters.
 * The function supports various file types and handles them with different strategies.
 * Not supported on React Native.
 * @param {string} filename - The base name of the file to be downloaded.
 * @param {any} data - The data to be included in the file.
 * @param {Array} columns - Contains the column metadata.
 * @param {Object} [fileType] - Contains file type information, like the extension.
 * @param {string} fileType.fileExt
 * @param {string} title - Title used for PDF exports.
 * @throws {Error} If the function is used in a React Native environment.
 *
 * This function is tough to unit test because it is supposed to cause the browser to download a file.
 * @link http://stackoverflow.com/questions/22733685/how-to-download-files-using-javascript-asynchronously
 * @link http://stackoverflow.com/questions/3665115/create-a-file-in-memory-for-user-to-download-not-through-server
 */
export default async function downloadTheFile(filename, data, columns, fileType = { fileExt: '.xlsx' }, title) {
    // eslint-disable-next-line no-undef
    if (typeof __SST_REACT_NATIVE__ !== 'undefined' && __SST_REACT_NATIVE__) {
        throw new Error('Not supported in React Native');
    }

    const handlers = {
        '.json': async () => {
            const jsonData = JSON.stringify(data, null, 3);
            sendToFile(filename, jsonData, fileType);
        },
        '.pdf': async () => {
            const action = 'exportPdf';
            const orientation = columns.length <= 2 ? 'portrait' : 'landscape';
            if (worker) {
                const result = await exportWithWorker({ action, data, columns, title, orientation });
                sendToFile(filename, result, fileType);
            } else {
                const { getPdfForExport } = await import('./getForExport');
                const result = await getPdfForExport({ data, columns, title, orientation });
                sendToFile(filename, result, fileType);
            }
        },
        default: async () => {
            const action = 'exportWorkbook';
            if (worker) {
                const result = await exportWithWorker({ action, data, columns, fileType });
                const blob = new Blob([stringToArrayBuffer(result)], { type: 'application/octet-stream' });
                sendToFile(filename, blob, fileType);
            } else {
                const { getWorkbookForExport } = await import('./getForExport');
                const file = await getWorkbookForExport({ data, columns, fileType });
                const blob = new Blob([stringToArrayBuffer(file)], { type: 'application/octet-stream' });
                sendToFile(filename, blob, fileType);
            }
        }
    };

    try {
        const exportFunc = handlers[fileType.fileExt] || handlers.default;
        await exportFunc();
    } catch (error) {
        logging.error('An error occurred during the file download process:', error);
    }
}

/**
 * Exports data with the help of a web worker. Initializes the worker if not already initialized.
 * Handles messaging with the worker and resolves or rejects based on the worker's response.
 * @param {Object} args - Arguments to be passed to the worker for processing.
 * @returns {Promise} A promise that resolves with the worker's output or rejects with an error message.
 */
function exportWithWorker(args) {
    return new Promise((resolve, reject) => {
        const currentWorker = initializeWorker();
        if (!currentWorker) {
            reject('Failed to initialize web worker');
            return;
        }

        currentWorker.onmessage = event => {
            resolve(event.data.result);
            terminateWorker();
        };
        currentWorker.onerror = event => {
            reject(event.message);
            terminateWorker();
        };
        currentWorker.postMessage(args);
    });
}

/**
 * Initiates the file download process by creating a link element and triggering a click event.
 * @param {string} filename - The name of the file to be downloaded.
 * @param {Blob} blob - The blob object representing the file content.
 * @param {Object} fileType - Contains file type information, including the extension.
 * @param {string} fileType.fileExt
 * @param {boolean} [fileType.showPreview ]
 */
export function sendToFile(filename, blob, fileType = {}) {
    filename = fileType.fileExt ? filename + fileType.fileExt : filename;

    if (window.navigator.msSaveOrOpenBlob) {
        if (fileType.showPreview) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            window.navigator.msSaveBlob(blob, filename);
        }
    } else {
        if (fileType.showPreview) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                let base64data = reader.result;
                // open a new window (tab)
                const w = window.open('');

                // Create an anchor to allow the file to be downloaded with a proper filename .
                const a = w.document.createElement('a');
                a.href = base64data;
                a.download = filename; // Set the file name.

                //create the image to be displayed
                const image = new Image();
                image.src = base64data;

                //embed the image in the anchor
                a.appendChild(image);
                // stick the anchor in the document
                w.document.body.appendChild(a);
                // w.document.write(image.outerHTML);
            };
            return;
        }
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename; // Set the file name.
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}

/**
 * Converts a string to an ArrayBuffer.
 * @param {string} str - The string to be converted.
 * @returns {ArrayBuffer} The ArrayBuffer representation of the input string.
 */
function stringToArrayBuffer(str) {
    const buffer = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
        buffer[i] = str.charCodeAt(i);
    }
    return buffer.buffer;
}
