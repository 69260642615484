import { createElement as rc, Fragment, useRef, useCallback, useEffect, useMemo } from 'react';
import { Card } from 'lib_ui-primitives';
import Form from '../form/Form';
import { Email, Password } from '../formElement';
import FormButton from '../formButton/FormButton';
import Disclaimer from './Disclaimer';
import useActiveRecord from '../../hooks/useActiveRecord';
import useAuthenticationType from './useAuthenticationType';
import useEventSink from '../../hooks/useEventSink';
import { nativeOnlyProperties } from 'lib_ui-services';
import FocusProvider from '../contextProviders/FocusProvider';
import { CardBox, HeaderText } from './styles';

const getHNode = ({ namespace, relation, type }) => ({
    id: 'offlinePinForm',
    title: '',
    hNodeType: 'Form',
    hNodeTypeGroup: 'form',
    children: [
        {
            id: 'loginEmail',
            title: 'Email',
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            propertyName: 'email',
            defaultValue: '',
            scanBarcode: true,
            scanRfid: false,
            treePosition: { sequence: 1 },
            transforms: [{ hNodeType: 'TabAfterScan' }]
        },
        {
            id: 'offlinePin',
            title: 'PIN',
            hNodeType: 'Password',
            hNodeTypeGroup: 'formElement',
            propertyName: 'pin',
            defaultValue: '',
            scanBarcode: true,
            scanRfid: false,
            treePosition: { sequence: 2 },
            transforms: [{ hNodeType: 'ActionAfterScan', forAction: 'submit', namespace, relation, subtype: type }]
        }
    ]
});
const formButtonHNode = {
    id: 'offlinePinContinue',
    title: 'Continue',
    hNodeType: 'FormButton',
    hNodeTypeGroup: 'formButton',
    hNodeBlockGroup: 'formButtons',
    formAction: 'submit'
};

const _p = { useAuthenticationType };
export const _private = _p;
export default function OfflinePin() {
    const errors = _p.useAuthenticationType('offlinePin');
    const emailRef = useRef();
    const pinRef = useRef();
    const { namespace, relation, record, type } = useActiveRecord() ?? {};
    const [, publish] = useEventSink();
    const hNode = useMemo(() => getHNode({ namespace, relation, type }), [namespace, relation, type]);
    useEffect(() => {
        setTimeout(() => {
            emailRef.current?.focus();
        }, 1000);
    }, []);
    const onKeyPress = useCallback(
        e => {
            if (e.key === 'Enter') {
                if (emailRef.current.value == null || emailRef.current.value.length === 0) {
                    emailRef.current.focus();
                } else if (pinRef.current.value == null || pinRef.current.value.length === 0) {
                    pinRef.current.focus();
                } else {
                    // See lib_ui-core/src/rules/security/profile/doingCreate_security_profile_offlinePin.js
                    publish({ record }, { verb: 'submit', namespace, relation, type });
                }
            }
        },
        [namespace, relation, publish, record, type]
    );

    // prettier-ignore
    return rc(Fragment, null,
        rc(FocusProvider, { name: 'offlinePin' },
            rc(CardBox, null,
                rc(Card.Header, { style: { justifyContent: 'center' } },
                    rc(HeaderText, { name: 'card-header-title' }, 'Offline PIN')
                ),
                rc(Card.Body, null,
                    rc(Form, { hNode, errors, popErrorNotifications: false, heightGreedy: false, centerFields: true },
                        rc(Email, {
                            ref: emailRef,
                            onKeyPress,
                            ...nativeOnlyProperties({
                                onChange: text => emailRef.current.value = text
                            }),
                            active: true,
                            hNode: hNode.children[0]
                        }),
                        // Used to capture PIN
                        rc(Password, {
                            ref: pinRef,
                            onKeyPress,
                            ...nativeOnlyProperties({
                                onChange: text => pinRef.current.value = text
                            }),
                            allowPasswordFill: true,
                            hNode: hNode.children[1]
                        }),
                        rc(FormButton, { hNode: formButtonHNode })
                    )
                )
            )
        ),
        rc(Disclaimer)
    );
}
