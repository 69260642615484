export default Object.freeze({
    sst: {
        contactUrl: 'https://sst.com/contact-sst'
    },
    redbeam: {
        knowledgeBase: 'https://support.redbeam.com/knowledge',
        contactUrl: 'https://redbeam.com/contact-redbeam',
        tryItNowUrl: 'https://redbeam.com/try-it-now'
    }
});
