import { memo, useState, useEffect, createElement as rc, useCallback } from 'react';
import { ProgressBar, Modal, h1, h3, Card, styled, fromTheme, webOnlyStyles, hooks } from 'lib_ui-primitives';
import useEventSink from '../../hooks/useEventSink';
import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
const { isEqual, cloneDeep, set, omit } = lodash;

const { useTimeout } = hooks;

const DELAY_BEFORE_CLOSING = 200;

const Description = styled(h3)`
    margin: ${fromTheme('viewMargin')};
`;

const MatchingCard = styled(Card)`
    width: 100%;
`;

const SkinnyModal = webOnlyStyles(Modal)`
    ${({ theme }) => !theme.mobile && 'height:unset;'}
`;
/**
 * A dialog component that displays a progress bar
 *
 * @param {Object} params
 * @param {boolean} [params.isClosable=false] determines if the modal can be closed or if it is visually blocking
 * @param {string} [params.description]
 * @param {string} params.mainTitle
 */
const ProgressDialog = () => {
    const [open, setOpen] = useState(false);
    const [subscribe] = useEventSink();
    const [scrollingRequired, setScrollingRequired] = useState(false);
    /**
     * progress hash is constructed to look like this:
     * {
     *      mainTitle0: {
     *          description: <optional description string>,
     *          title0: <some 0-100 int value>
     *          title1: <some 0-100 int value>
     *      }
     *      mainTitle1: {
     *          description: <optional description string>,
     *          title2: <some 0-100 int value>
     *          title3: <some 0-100 int value>
     *      }
     * }
     * each mainTitle results in a separate card in the dialog
     * each description describes the data in the card
     * each title results in a separate progress bar inside the card
     */
    const [progress, setProgress] = useState({});

    const closeTimeout = useTimeout(() => setOpen(false), [], DELAY_BEFORE_CLOSING);
    const updateProgress = useCallback(
        progressChange => {
            if (progressChange == null) {
                logging.error('Application progress events must include a payload.');
                return;
            }

            const { mainTitle, title, description, current, total } = progressChange;
            if (mainTitle == null) {
                logging.error('Application progress events must include a main title in the payload.');
                return;
            }
            setProgress(prev => {
                // deep clone to avoid previous value having the same internal ref and
                // getting updated by accident
                let newProgress = cloneDeep(prev);
                // Reset verbs will not have a 'total' value so remove the entry in this case
                // If a `title` is provided, just remove that one single bar.
                // If that was the last progress bar for that `mainTitle`, or if no `title` is provided,
                // remove the entire progress bar container.
                if (total == null) {
                    if (title == null) {
                        delete newProgress[mainTitle];
                    } else if (newProgress?.[mainTitle]?.[title]) {
                        delete newProgress[mainTitle][title];
                        //if at most `description` is left:
                        if (Object.keys(omit(newProgress[mainTitle], 'description')).length <= 0) {
                            //remove this whole progress object
                            delete newProgress[mainTitle];
                        }
                    }
                } else {
                    // Don't remove description if a subsequent progress updated does
                    // not have it.
                    if (description != null) {
                        set(newProgress, [mainTitle, 'description'], description);
                    }
                    set(newProgress, [mainTitle, title], Math.ceil((current / total) * 100));
                }
                // avoid unnecessary renders by retaining the previous ref if nothing changed.
                return isEqual(prev, newProgress) ? prev : newProgress;
            });
        },
        [setProgress]
    );

    useEffect(() => {
        const numberCards = Object.entries(progress).length;

        setScrollingRequired(numberCards > 1);
        // if there are zero mainTitle entries:
        // Wait DELAY_BEFORE_CLOSING mills for user to see the previous update
        // and then close the dialog
        if (numberCards === 0) {
            closeTimeout();
        } else {
            // Open the dialog if there are any mainTitle entries
            setOpen(true);
        }
    }, [progress, closeTimeout]);

    useEffect(() => {
        const unsubscribes = [
            subscribe({ verb: 'update', namespace: 'application', relation: 'progress' }, updateProgress),
            subscribe({ verb: 'reset', namespace: 'application', relation: 'progress' }, updateProgress)
        ];
        return () => {
            unsubscribes.forEach(u => u());
        };
    }, [subscribe, updateProgress]);

    // prettier-ignore
    return rc(SkinnyModal, { id: 'progressDialogModal', visible: open, scrollingRequired, 'data-testid': 'modal-content' },
        Object.entries(progress).map(([key, value]) => {
            const { description, ...scrollbars } = value;
            return rc(MatchingCard, { key },
                rc(Card.Header, null,
                    rc(h1, null, key)
                ),
                rc(Card.Body, null,
                    rc(Description, null, description),
                    Object.entries(scrollbars).map(([key, value]) => {
                        return rc(ProgressBar, { key, progress: value, title: key });
                    })
                )
            );
        })
    );
};

export default memo(ProgressDialog);
