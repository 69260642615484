import { createElement as rc, useMemo } from 'react';
import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
const { sortBy } = lodash;
import FilterContainer from './FilterContainer';
import DownloadButton from '../actionElement/DownloadButton';
import useDbView from '../../hooks/useDbView';
import { Card, View, h1, h2, styled, fromTheme } from 'lib_ui-primitives';

const ChartHeader = styled(Card.Header).attrs({ name: 'chart-header' })`
    justify-content: space-between;
    flex-wrap: wrap;
`;
ChartHeader.displayName = 'ChartHeader';
const HeaderButtons = styled(View).attrs({ name: 'header-buttons' })`
    align-items: flex-end;
`;
HeaderButtons.displayName = 'HeaderButtons';

const ChartBody = styled(Card.Body).attrs({ name: 'chart-body' })`
    padding: ${fromTheme('viewPadding')};
`;
ChartBody.displayName = 'ChartBody';

const Widget = styled(Card)`
    flex-grow: 1;
    flex-shrink: 1;
    background-color: ${fromTheme('backgroundColorDarker')};
`;

const Title = styled(h1)`
    font-size: ${fromTheme('fontSize')};
`;

const Centered = styled(View)`
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
`;

const NoRecords = styled(h2)`
    font-family: Arial;
    font-weight: 900;
    color: ${fromTheme('disabledFontColor')};
`;

const EMPTY_ARRAY = [];

const _p = { useDbView };
export const _private = _p;
function Chart(nestedComponent, props) {
    const {
        children = EMPTY_ARRAY,
        hNode,
        hNode: { id, namespace, relation, limitResults = 21 }
    } = props || {};

    const hNodeChildren = hNode.children ?? EMPTY_ARRAY;
    const { records, recordCount, calculateAggregateResult, viewReady } = _p.useDbView(
        namespace,
        relation,
        undefined,
        hNode
    );

    //include records in the dependency array to trigger a (recalculate and) rerender any time the records change.
    //No idea how this will hold up with e.g. 100K records. If that causes issues we might need to use throttle?
    const aggregateResult = useMemo(() => {
        logging.debug(`[CHART] Recalculating chart ${hNode.title} result`);
        const results = calculateAggregateResult(records);
        return sortBy(results, ['count']).reverse();
    }, [hNode, records, calculateAggregateResult]);

    const truncatedAggregateResult = useAggregateGroupByResults(aggregateResult || EMPTY_ARRAY, limitResults);

    const filterControls = useMemo(
        () =>
            children.filter(
                child =>
                    (child.props.hNode.hNodeTypeGroup === 'actionElement' &&
                        child.props.hNode.hNodeType !== 'DownloadButton') ||
                    child.props.hNode.hNodeTypeGroup === 'searchPane'
            ),
        [children]
    );

    const downloadButton = hNodeChildren.find(child => child.hNodeType === 'DownloadButton');
    // prettier-ignore
    return rc(Widget, null,
        rc(ChartHeader, null,
            rc(Title, null, hNode?.title),
            rc(HeaderButtons, null,
                rc(FilterContainer, null, filterControls),
                downloadButton && rc(DownloadButton, {
                    id: `download${id}`,
                    hNode: {
                        ...downloadButton,
                        displayTitleOnButton: false,
                        buttonStyle: downloadButton.style,
                        filePrefix: hNode?.title || `chart_${namespace}_${relation}`
                    },
                    data: aggregateResult
                })
            )
        ),
        rc(ChartBody, null,
            rc(Centered, null,
                viewReady
                    ? recordCount
                        ? rc(nestedComponent, { ...props, data: truncatedAggregateResult })
                        : rc(NoRecords, { id }, `No ${hNode?.title || 'Records'}`)
                    : rc(NoRecords, { id }, 'Loading...')
            )
        )
    );
}

/**
 * Aggregates an array of groupBy results
 * @param {Object[]} data an array of object to be sorted and trucated
 * @param {number} numberOfResults how many results you want to display before aggregating the rest
 * @param {string} aggregateValueLabel the label to be used for the trucated value
 * @returns {Object[]}
 */
function useAggregateGroupByResults(data, numberOfResults = 0, aggregateValueLabel = 'Other') {
    return useMemo(() => {
        return aggregateGroupByResults(data, numberOfResults, aggregateValueLabel);
    }, [data, numberOfResults, aggregateValueLabel]);
}

export function aggregateGroupByResults(data, numberOfResults, aggregateValueLabel) {
    if (!Array.isArray(data)) {
        throw new Error('The data argument must be an array of Objects');
    }

    if (isNaN(+numberOfResults)) {
        throw new Error('the numberOfResults argument must be a number');
    }
    // if number of results is 0 or the number of results would not end up creating a truncation, we return the input array
    if (+numberOfResults === 0 || data.length <= +numberOfResults) {
        return data;
    }

    numberOfResults--;
    const displayedData = data.slice(0, numberOfResults);
    const aggregatedData = data.slice(numberOfResults);
    const aggregatedValue = aggregatedData.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.y;
    }, 0);
    return [
        ...displayedData,
        {
            _id: { title: aggregateValueLabel },
            name: aggregateValueLabel,
            y: aggregatedValue
        }
    ];
}

export default Chart;
