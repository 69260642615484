import { createElement as rc, useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { View, CheckBox, RadioButton, fromTheme, styled, useDebounce, Text } from 'lib_ui-primitives';
import useColumnCell from '../columns/useColumnCell';
import useMultiSelect from '../../../hooks/useMultiSelect';
import { getColumnHNodes, getColumnWidths } from '../columns/useColumnWidths';
import lodash from 'lodash';
const { isEqual } = lodash;
//import useWhatChanged from '../../../../utility/useWhatChanged';

const LastRow = styled(View)`
    display: flex;
    flex-direction: row;
    background-color: 'transparent';
    height: ${fromTheme('listLineHeight')};
    align-items: flex-end;
`;
const EndOfRecords = styled(Text).attrs({ name: 'end-of-records' })`
    margin-left: ${fromTheme('textMargin')};
    font-style: italic;
    font-size: ${fromTheme('fontSizeSmall')};
    color: ${fromTheme('disabledFontColor')};
`;
EndOfRecords.displayName = 'EndOfRecords';
const Row = styled(View)`
    display: flex;
    flex-direction: row;
    background-color: ${props =>
        props.singleRowSelect && props.selected
            ? props.theme.listSelectedBackgroundColor
            : props.altRow
              ? 'transparent'
              : props.theme.backgroundColor};
    height: ${fromTheme('listLineHeight')};
    align-items: center;
`;

const FirstCell = styled(View)`
    margin-left: ${fromTheme('viewMargin')};
    margin-right: -${({ theme }) => theme.viewMargin}px;
`;

const CheckBoxFirstCell = styled(View)`
    align-items: center;
    padding: 0 ${fromTheme('viewPaddingMore')} 0 ${fromTheme('viewPadding')};
`;

// Add breaks between cells by increasing border-left-width:
// border-left-width: 1px;
// border-left-color: black;
let NotFirstCell = styled(View)`
    background-color: ${({ altCol, theme }) => (altCol ? theme.backgroundColorLighter : 'transparent')};
    height: ${fromTheme('listLineHeight')};
    align-items: center;
`;

// jsDom does not have a layout engine, so scrollLayout needs to be defaulted
// during testing (otherwise list/row clientWidths will be zero and no columns
// will be rendered).
// eslint-disable-next-line no-undef
const defaultClientWidth = __UNIT_TESTING__ ? 1500 : 0;
export const _private = { defaultClientWidth };
export default function useRowDetail(hNode, _columnHNodes, onRowClick, endOfRecordsText) {
    if (hNode.children == null) {
        throw new Error(`A ${hNode.hNodeType} must contain metadata columns.`);
    }
    const [scrollLayout, setScrollLayout] = useState({ clientWidth: _private.defaultClientWidth, scrollLeft: 0 });
    const onScrollLayoutChange = useDebounce(
        scrollLayout => {
            // Prevent a rerender when the layout individual values are the same, but the
            // reference has changed.
            setScrollLayout(previous => {
                if (
                    previous.clientHeight !== scrollLayout.clientHeight ||
                    previous.clientWidth !== scrollLayout.clientWidth ||
                    previous.scrollHeight !== scrollLayout.scrollHeight ||
                    previous.scrollWidth !== scrollLayout.scrollWidth ||
                    previous.scrollTop !== scrollLayout.scrollTop ||
                    previous.scrollLeft !== scrollLayout.scrollLeft
                ) {
                    return scrollLayout;
                }
                return previous;
            });
        },
        [],
        100
    );
    const [visibleColumnIndexes, setVisibleColumnIndexes] = useState([]);
    const [prefixColumnBufferWidth, setPrefixColumnBufferWidth] = useState(0);
    const [suffixColumnBufferWidth, setSuffixColumnBufferWidth] = useState(0);
    const theme = useTheme();
    const { children, allowCheckUnknownTags = false } = hNode;

    const columnHNodes = useMemo(() => getColumnHNodes(_columnHNodes, children), [_columnHNodes, children]);
    const columnWidths = useMemo(() => getColumnWidths(columnHNodes, theme), [columnHNodes, theme]);

    // Determine which columns should be rendered in the current
    // visible window that this row overlaps.
    useEffect(() => {
        const { clientWidth, scrollLeft } = scrollLayout;
        const windowStart = scrollLeft;
        const windowEnd = scrollLeft + clientWidth;
        const visibleColumnIndexes = [];
        let runningTotal = 0;
        let _prefixColumnBufferWidth = 0;
        let _suffixColumnBufferWidth = 0;
        for (let i = 0; i < columnWidths.length; i++) {
            const width = columnWidths[i];
            const columnStart = runningTotal;
            const columnEnd = runningTotal + width;
            if (columnEnd > windowStart && columnStart < windowEnd) {
                visibleColumnIndexes.push(i);
            } else if (columnEnd <= windowStart) {
                _prefixColumnBufferWidth += width;
            } else if (columnStart >= windowEnd) {
                _suffixColumnBufferWidth += width;
            } else {
                throw new Error('There is a problem with the column width calculations.');
            }
            runningTotal = columnEnd;
        }
        setPrefixColumnBufferWidth(_prefixColumnBufferWidth);
        setSuffixColumnBufferWidth(_suffixColumnBufferWidth);
        setVisibleColumnIndexes(prev => {
            if (!isEqual(visibleColumnIndexes, prev)) {
                return visibleColumnIndexes;
            }
            return prev;
        });

        // ** Useful for debugging: For measuring framerates with ALL columns rendered
        // setPrefixColumnBufferWidth(0);
        // setSuffixColumnBufferWidth(0);
        // setVisibleColumnIndexes([...Array(columnWidths.length).keys()]);

        // *** Useful for debugging, but not worth the clutter otherwise. ***
        // logging.debug('[useRowDetail] column names: ', columnHNodes.map(c => c.title).join(', '));
        // logging.debug('[useRowDetail] visibleColumnIndexes', JSON.stringify(visibleColumnIndexes));
    }, [scrollLayout, columnWidths]);

    const { includeCheckbox, singleRowSelect, namespace, relation } = hNode;
    const { onIndividualChange, isSelected } = useMultiSelect({ namespace, relation, singleRowSelect });

    // ** Useful for debugging: for figuring out why things are rerendering **/
    //useWhatChanged({ allowCheckUnknownTags, columnHNodes, includeCheckbox, isSelected, onIndividualChange, prefixColumnBufferWidth, suffixColumnBufferWidth, visibleColumnIndexes });
    const RowDetail = useMemo(
        () => props => {
            const { item, rowIndex, isLastItem } = props;
            const _id = item._id;
            const isUnknown = item.inDatabase === false;
            const disabledCheckbox = isUnknown && !allowCheckUnknownTags;
            // Never allowed a disabled row checkbox to be selected
            const selected = !disabledCheckbox && isSelected(_id);
            // This is actually a component that will be rendered so useEffect is ok.
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
                if (isUnknown && !allowCheckUnknownTags && selected) {
                    onIndividualChange(_id, false);
                }
            }, [isUnknown, _id, selected]);

            // This is actually a component that will be rendered so useCallback is ok.
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const onClick = useCallback(
                value => {
                    if (disabledCheckbox) return;
                    onIndividualChange(_id, value);
                    if (value && !selected && singleRowSelect) {
                        onRowClick?.({ _id });
                    }
                },
                [_id, disabledCheckbox, selected]
            );
            /* This part is confusing.  The way we delegate rendering so that the top component
            (e.g. TableList in this case ) has more control, is helpful in some ways and
            confusing in others. If you look at List/index.js, you will see that I increase
            the item count by one if endOfRecordsText is present.  This should allow all
            records to be rendered here.
            */
            if (isLastItem && endOfRecordsText?.length > 0) {
                // prettier-ignore
                return rc(LastRow, null,
                    rc(EndOfRecords, null, endOfRecordsText)
                );
            }
            const style = getFlagStyle(item, columnHNodes);
            // prettier-ignore
            return rc(Row, { altRow: rowIndex % 2 === 0, singleRowSelect, selected, style},
                // blank view to take up space
                includeCheckbox && rc(CheckBoxFirstCell, {},
                    !singleRowSelect && rc(CheckBox, { disabled: disabledCheckbox, onClick, value: (!isUnknown || allowCheckUnknownTags) && selected }),
                    singleRowSelect && rc(RadioButton, { id:_id, disabled: disabledCheckbox, name:relation, onClick, value: (!isUnknown || allowCheckUnknownTags) && selected })
                ),
                rc(View, { key: 'prefixColumn', name: 'prefix-column', style: { width: prefixColumnBufferWidth } }),
                columnHNodes.map((colHNode, i) => {
                    if (!visibleColumnIndexes.includes(i)) {
                        return null;
                    }
                    return i === 0 && !includeCheckbox
                        ? rc(FirstCell, { key: 'cell' + i }, rc(Cell, { item, hNode: colHNode, width: columnWidths[i] }))
                        : rc(NotFirstCell, { altCol: i % 2 === 1, key: 'cell' + i }, rc(Cell, { item, hNode: colHNode, width: columnWidths[i] }));
                }),
                rc(View, { key: 'suffixColumn', name: 'suffix-column', style: { width: suffixColumnBufferWidth } })
            );
        },
        [
            allowCheckUnknownTags,
            columnHNodes,
            includeCheckbox,
            isSelected,
            onIndividualChange,
            onRowClick,
            prefixColumnBufferWidth,
            relation,
            singleRowSelect,
            suffixColumnBufferWidth,
            visibleColumnIndexes,
            endOfRecordsText,
            columnWidths
        ]
    );

    RowDetail.displayName = 'RowDetail';
    return [RowDetail, onScrollLayoutChange];
}

export function Cell(props) {
    const { item, hNode, style, width } = props;
    return useColumnCell(item, hNode, style, width);
}

function getFlagStyle(item, columnHNodes = []) {
    return columnHNodes.reduce((style, column) => {
        if (column.visuallyFlagOnValue) {
            const value = item[column.propertyName] || '';
            if (value && value.trim() === column.visuallyFlagOnValue.trim()) {
                //TODO: we probably want to allow for different styling options
                return { ...style, borderLeft: '6px solid #C6110B', fontWeight: 'bold' };
            }
        }
        return style;
    }, {});
}
