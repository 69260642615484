import { useContext, useCallback, useEffect, useState } from 'react';
import lodash from 'lodash';
import useEventSink from './useEventSink';
import useActiveRecord from './useActiveRecord';
import useFormContext from './useFormContext';
import useDefaultValueForHNode from './useDefaultValueForHNode';
import { hooks, contexts } from 'lib_ui-primitives';
import { constants, metadata } from 'lib_ui-services';
const { useMemoizedObject } = hooks;

// For comparing hook runs to see what changed.  See 'FOR DEBUGGING' comment below
// import { useWhatChanged } from '@simbathesailor/use-what-changed';
import useGetMemoized from './useGetMemoized';
import { useMoveFocusToNext } from '../components/contextProviders/FocusProvider/useMoveFocusToNext';

const { isEqual } = lodash;
const { get } = lodash;
// Avoid rerenders by using a constant for this.
const DEFAULT_LOCAL_ERROR_MESSAGES = [];
const { getPathToProperty } = metadata;
/**
 *
 * @param {Object} props
 * @param {Object} props.hNode
 * @param {string} [props.hNode.title]
 * @param {string} [props.hNode.autocomplete='off']
 * @param {string} props.hNode.defaultValue
 * @param {string} props.hNode.foreignNamespace
 * @param {string} props.hNode.foreignRelation
 * @param {string} props.hNode.propertyName
 * @param {string} props.hNode.propertyPath
 * @param {boolean} props.hNode.readOnly
 * @param {Array} [props.hNode.readOnlyFor]
 * @param {(event: import('react').SyntheticEvent)=>void} [onFocus]
 * @param {(event: import('react').SyntheticEvent)=>void} [onBlur]
 */
export default function useFormControl(props, overrideDefaultValue) {
    const { onFocus: _onFocus, onBlur: _onBlur, onChange } = props || {};
    const session = useContext(contexts.SessionContext);
    const [active, setActive] = useState(false);
    const { namespace, relation, type } = useActiveRecord();
    const { disabled, newRecord, oldRecord, errorsByPath = {}, autoFocusHNodeId } = useFormContext();
    const [subscribe, publish] = useEventSink();
    const {
        hNode,
        hNode: { title, propertyName, foreignNamespace, foreignRelation, autocomplete = 'off' }
    } = props;
    const path = getPathToProperty(hNode);
    const [localErrorMessages, setLocalErrorMessages] = useState(DEFAULT_LOCAL_ERROR_MESSAGES);
    const { defaultValue, defaultReady } = useDefaultValueForHNode(hNode, overrideDefaultValue);
    const oldValue = useGetMemoized(oldRecord, path, defaultValue);
    const newValue = useGetMemoized(newRecord, path);
    const isDefaultValue = newValue == null || newValue?.isDefaultRecord;
    const value = newValue == null ? defaultValue : newValue;
    const moveFocusToNext = useMoveFocusToNext(hNode.id);

    const doAfterBarcodeScan = useCallback(() => {
        if (hNode.transforms?.some(t => t.hNodeType === 'TabAfterScan')) {
            moveFocusToNext();
        } else {
            const postScanActions = hNode.transforms?.filter(t => t.hNodeType === 'ActionAfterScan') || [];
            postScanActions.forEach(action => {
                const { namespace, relation, forAction, subtype } = action;
                const unsubscribe = subscribe({ verb: 'change', namespace, relation, status: 'success' }, () => {
                    unsubscribe();
                    publish(hNode, { verb: forAction, namespace, relation, type: subtype });
                });
            });
        }
    }, [hNode, moveFocusToNext, publish, subscribe]);

    let errors = get(errorsByPath, path, []);
    if (!Array.isArray(errors)) errors = [errors];
    errors = useMemoizedObject([...errors, ...localErrorMessages]);
    const isDirty = !isEqual(oldValue, value);
    const readOnly = (hNode.readOnlyFor ?? []).includes(session?.role?.title) || hNode.readOnly;

    const addErrorMessage = useCallback(errorMessage => {
        if (typeof errorMessage !== 'string') {
            throw new Error('addErrorMessage must be passed a string.');
        }
        setTimeout(() => {
            setLocalErrorMessages(prev => {
                if (!prev.includes(errorMessage)) {
                    return [...prev, errorMessage];
                }
                return prev;
            });
        }, 0);
    }, []);

    const setValue = useCallback(
        (changeValue, inputSource) => {
            const { id, foreignNamespace, foreignRelation, propertyName, propertyPath = '' } = hNode;
            let newValue = changeValue;
            // This allows setValue to behave like the typical setValue from a useState method
            // (where sometimes a function is passed in instead of the actual value)
            if (typeof changeValue === 'function') {
                newValue = changeValue(value);
            }
            // Deep comparison for arrays - Typically used for WorkflowCriteria
            if (Array.isArray(newValue) && Array.isArray(value) && isEqual(newValue, value)) {
                return;
            }
            // Typically something like a dropdown would pass a selected item object into here that needs cleaning up
            if (typeof newValue === 'object' && !(newValue instanceof Date) && !Array.isArray(newValue)) {
                // If the control's default record was selected, then corresponding field
                // value should be removed from the record.
                if (newValue.isDefaultRecord) {
                    newValue = undefined;
                } else {
                    if (foreignNamespace && foreignRelation) {
                        // avoid rerenders by using the same ref if possible
                        if (isEqual(value, newValue)) {
                            newValue = value;
                        } else {
                            // Remove metadata, etc. from selected record
                            newValue = metadata.getMinimumForeignKeyFields(changeValue, hNode);
                        }
                    }
                }
                publish(
                    {
                        newValue,
                        inputSource
                    },
                    { verb: 'controlChange', hNodeId: id }
                );
            }
            // Clear out a saved default value if necessary (see useDefaultValueForHNode.js)
            if (newValue == null && value != null) {
                publish(
                    {
                        newValue,
                        inputSource
                    },
                    { verb: 'controlChange', hNodeId: id }
                );
            }
            setLocalErrorMessages(DEFAULT_LOCAL_ERROR_MESSAGES);
            // Typically consumed by the form state machine (see useFormMachine.js)
            publish(
                {
                    propertyName,
                    foreignNamespace,
                    foreignRelation,
                    newValue,
                    inputSource,
                    propertyPath
                },
                { verb: 'beginChange', namespace, relation, type }
            );
            onChange?.(newValue);
            if (inputSource === constants.sensorTypes.BARCODE) {
                doAfterBarcodeScan();
            }
        },
        [namespace, relation, type, hNode, publish, value, onChange, doAfterBarcodeScan]
    );

    const onFocus = useCallback(
        /**
         * @param {import('react').SyntheticEvent} event
         */
        event => {
            setActive(true);
            event.target.select?.();
            _onFocus?.();
        },
        [_onFocus]
    );

    const onBlur = useCallback(
        e => {
            // Do not blur if the user clicked on a scan button (https://stackoverflow.com/a/60094794/1356444).
            // (relatedTarget will be the scan button in that case).
            if (e.relatedTarget == null || !e.currentTarget.contains(e.relatedTarget)) {
                setActive(false);
                _onBlur?.();
            }
        },
        [_onBlur]
    );
    // FOR DEBUGGING:
    // If you need to compare runs of the following useEffect hook to see what changed, uncomment the following two lines
    // and put `deps` as your dependency array.  Also verify that these lines still has the same dependencies as the hook. :)
    // const deps = [value, foreignNamespace, foreignRelation, publish];
    // useWhatChanged(deps, 'value, foreignNamespace, foreignRelation, publish');

    useEffect(() => {
        // Inform filters (e.g. foreignRelationSelectionOnForm) for other dropdowns etc
        // that need to react to a value change.
        // Typically consumed by FilterInterdependencyBoundary.js
        if (foreignNamespace != null && foreignRelation != null) {
            publish(
                { value },
                {
                    verb: 'select',
                    namespace: foreignNamespace,
                    relation: foreignRelation,
                    status: 'success'
                }
            );
        }
    }, [value, foreignNamespace, foreignRelation, publish]);

    return {
        active,
        autoComplete: isDirty ? 'off' : autocomplete,
        disabled: disabled || readOnly,
        errors,
        addErrorMessage,
        isDirty,
        onBlur,
        onFocus,
        autoFocus: autoFocusHNodeId === hNode.id,
        setValue,
        title: title ?? propertyName,
        value,
        isDefaultValue,
        defaultReady
    };
}
