/**
 * explicitly always allow filter on the root _id
 * Having access to records without a value makes no difference here
 *
 * Each query will look something like this:
 * ```
 * { '_id:' { $in: ['1234', '5678'] } }
 * ```
 *
 * @param {*} paths - unused. "Hardcoded" to ['_id']
 * @param {Array<{_id:string}>} filterValues - Array of values to filter by
 * @returns {object} - filter object
 */
export default function rootIdFilter(paths, filterValues) {
    if (!filterValues?.length) return undefined;

    // extract the _ids and filter out the special case of allowing undefined values
    const actualValues = filterValues.map(({ _id }) => _id).filter(_id => _id !== '0'.repeat(24));

    if (!actualValues?.length) return undefined;

    const criteria =
        actualValues.length > 1
            ? {
                  $in: actualValues
              }
            : {
                  $eq: actualValues[0]
              };
    return { _id: criteria };
}
