/**
 *
 * @param {*} file
 * @param {*} header
 * @returns {Promise<Array<Array<string|number|Date>> | Promise<Array<object>>}
 */
export async function getData(file, header) {
    const dynamicGetImportFromWorkbook = await import(
        /*webpackChunkName WorkbookForImport*/ './dynamicGetImportFromWorkbook'
    );
    return await dynamicGetImportFromWorkbook.default(file, header);
}

export default {
    getData
};
