import { isPast, parseISO } from 'date-fns';
import logging from '@sstdev/lib_logging';

export default function getIfContractExpired(tenant) {
    // If there is no tenant, then we are most likely on the tenant selection page.
    if (!tenant) {
        return false;
    }

    const isExpired = tenant.contractEndDate && isPast(parseISO(tenant.contractEndDate));

    if (isExpired) {
        logging.info('[SESSION] Contract has expired.');
    }

    return isExpired;
}
