import logging from '@sstdev/lib_logging';

export default async function unregisterRedirectWorker() {
    if (typeof window === 'undefined' || typeof navigator === 'undefined') {
        logging.warn('[SERVICEWORKER] unregisterRedirectWorker called in an environment without window or navigator');
        return;
    }
    try {
        // Remove all service workers for the domain
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of registrations) {
            if (registration?.active?.scriptURL?.includes('redirectWorker.js')) {
                await registration.unregister();
            }
        }
    } catch (e) {
        logging.error('[SERVICEWORKER] unregisterRedirectWorker failed.', e);
    }
}
