import lodash from 'lodash';
const { set } = lodash;
const { get } = lodash;
import debounce from 'debounce-promise';
import firepants from './firepants/index';
import logging from '@sstdev/lib_logging';
import Synchronization from './synchronization';
import count from './databaseOperations/count';
import clear from './databaseOperations/clear';
import getById from './databaseOperations/getById';
import getByTagId from './databaseOperations/getByTagId';
import getByTagIds from './databaseOperations/getByTagIds';
import insert from './databaseOperations/insert';
import upsert from './databaseOperations/upsert';
import update from './databaseOperations/update';
import updateMany from './databaseOperations/updateMany';
import removeMany from './databaseOperations/removeMany';
import remove from './databaseOperations/remove';
import bulkUpsert from './databaseOperations/bulkUpsert';
import find from './databaseOperations/find';
import deleteDatabase from './databaseOperations/deleteDatabase';
import forceInventorySync from './databaseOperations/forceInventorySync';
import relationTotalSize from './databaseOperations/relationTotalSize';
import truncateCollectionToMaxSize from './databaseOperations/truncateCollectionToMaxSize';
import localStorage from '../localStorage';
import * as offlineResilientCommunication from '../offlineResilientCommunication/index';
import * as metadata from '../metadata';

const initialDatabaseStats = {
    initialSyncFinished: false,
    lastSyncRecordCompleted: undefined,
    nextBatchdQueryIndexKey: undefined,
    lastSyncTime: undefined,
    syncStartTime: undefined,
    lastPurgeTime: undefined,
    lastBatch: false
};

const requiredSettings = ['actionPublisher', 'config', 'namespaces', 'tenantId', 'useCaseId'];
/**
 * Generalize CRUD to the various relations/databases/document collections (one per
 * namespace/relation combination).
 * Also handles synchronization with the backend.
 * This will lazily create separate storage areas for each tenant/useCase combination
 * using it.
 */
class Database {
    constructor(settings, storageType = 'LokiJs') {
        // Make sure we have all the required settings.
        const settingsPassed = Object.keys(settings);
        requiredSettings.forEach(rs => {
            if (!settingsPassed.includes(rs)) throw new Error(`Database construction settings must include ${rs}.`);
        });
        // publish allows dispatching actions back to the main thread (or if this is
        // called by the main thread, it just dispatches the action).
        this.publish = settings.actionPublisher;
        this.settings = settings;
        this.storageType = storageType;
        // This holds references to a different database for each namespace/relation.
        this._relationDb = {};
        // debounce and encapsulate updating the allStorageState.  This is to avoid
        // writing to disk a gazillion times when the various sync statistics are being
        // updated.
        this.persistStorageState = debounce(
            (() => {
                return localStorage.setKey('allStorageState', JSON.stringify(this.allStorageState), '', false);
            }).bind(this),
            200
        );

        this.setStorageState = this.setStorageState.bind(this);
        this.getStorageState = this.getStorageState.bind(this);
        this.resetStorageState = this.resetStorageState.bind(this);
        this.isNewDatabase = this.isNewDatabase.bind(this);
        this.dropCollection = this.dropCollection.bind(this);

        this.synchronization = new Synchronization(this);

        //actual CRUD operations:
        this.clear = clear(this);
        this.count = count(this);
        this.getById = getById(this);
        this.getByTagId = getByTagId(this);
        this.getByTagIds = getByTagIds(this);
        this.insert = insert(this);
        this.upsert = upsert(this);
        this.update = update(this);
        this.updateMany = updateMany(this);
        this.removeMany = removeMany(this);
        this.remove = remove(this);
        this.find = find(this);
        this.bulkUpsert = bulkUpsert(this);
        this.deleteDatabase = deleteDatabase(this);
        this.forceInventorySync = forceInventorySync(this);
        this.relationTotalSize = relationTotalSize(this);
        this.truncateCollectionToMaxSize = truncateCollectionToMaxSize(this);
    }

    // make sure there is a unique index that signifies the tenant and useCase.  This is
    // so the same device can be used with multiple tenants and useCases without data
    // overlapping.
    // NOTE:  Unfortunately, this must be called separately from the constructor because
    // it makes an async call.  It might be worth it to make database.js a singleton so
    // that the factory method can do this initialization and return a promise.  Not sure.
    async initializeStorage() {
        logging.debug('[DATABASE] Initializing storage');
        const result = await localStorage.getKey('allStorageState', undefined, undefined, false);
        this.allStorageState = JSON.parse(result || '{}');
        this.storageState = get(this.allStorageState, `${this.settings.useCaseId}.${this.settings.tenantId}`, {});

        if (!this.storageState.sequence) {
            this.allStorageState.lastSequence = (this.allStorageState.lastSequence || 0) + 1;
            this.storageState.sequence = this.allStorageState.lastSequence;
        }

        this.abstractDbRoot = this.getDatabaseRoot();
        this.synchronization.cleanUpBrokenSyncs();
        this.persistStorageState();

        await this.abstractDbRoot.waitTillDbReady;
        logging.debug('[DATABASE] Storage initialized');
        return this;
    }

    async deleteAllDatabases(payload, context) {
        try {
            // Delete the current loki database using the
            // method that is aware of loki.  This will clear
            // the in-memory portion as well so it doesn't try
            // to write to indexedDB after this is finished.
            await this.deleteDatabase();
            // Now directly delete the indexeddb database to get the rest
            // of the tenants and use cases
            await new Promise((resolve, reject) => {
                const request = self.indexedDB.deleteDatabase('LokiCatalog');
                request.onerror = event => reject(event.error);
                request.onblocked = () => {
                    // This won't matter if this workflow is going to restart the page.
                    logging.warn(
                        '[DATABASE] Cannot delete all databases because there is a blocking connection.  Did you close the loki database?'
                    );
                };
                request.onsuccess = () => resolve();
            });
            // Now reset all the sync counters.
            Object.keys(this.allStorageState).forEach(key => {
                if (key !== 'lastSequence') {
                    const useCase = this.allStorageState[key];
                    Object.keys(useCase).forEach(tenantId => {
                        Object.keys(useCase[tenantId]).forEach(namespaceTitle => {
                            if (namespaceTitle !== 'sequence') {
                                const namespace = useCase[tenantId][namespaceTitle];
                                Object.keys(namespace).forEach(relationTitle => {
                                    this.setStorageState(
                                        namespaceTitle,
                                        relationTitle,
                                        'latestModifiedTime',
                                        undefined
                                    );
                                    this.setStorageState(
                                        namespaceTitle,
                                        relationTitle,
                                        'earliestModifiedTime',
                                        undefined
                                    );
                                    this.setStorageState(namespaceTitle, relationTitle, 'lastSyncTime', undefined);
                                    this.setStorageState(namespaceTitle, relationTitle, 'initialSyncFinished', false);
                                    this.setStorageState(
                                        namespaceTitle,
                                        relationTitle,
                                        'lastSyncRecordCompleted',
                                        undefined
                                    );
                                });
                            }
                        });
                    });
                }
            });
            this.persistStorageState();
            this.dispatch([], payload, context);
        } catch (err) {
            logging.error(err);
            const errorPayload = { ...payload, errors: { field: {}, form: [err.stack || err.message || err] } };
            const failureContext = { ...context, status: 'failure' };
            this.dispatch([], errorPayload, failureContext);
        }
    }

    /**
     * Lazily create a database for a requested namespace and relation title combo.
     * @param {string} namespaceTitle
     * @param {string} relationTitle
     */
    relationDb(namespaceTitle, relationTitle) {
        if (typeof namespaceTitle !== 'string' || typeof relationTitle !== 'string') {
            logging.warn('[DATABASE] Invalid input: namespaceTitle and relationTitle must be strings');
            return null;
        }

        const collection = get(this._relationDb, `${namespaceTitle}.${relationTitle}`, false);
        if (!collection) {
            this.initializeDb(namespaceTitle, relationTitle);
        }
        return this._relationDb[namespaceTitle]?.[relationTitle] || null;
    }

    initializeDb(namespaceTitle, relationTitle) {
        if (typeof namespaceTitle !== 'string' || typeof relationTitle !== 'string') {
            logging.warn('[DATABASE] Invalid input: namespaceTitle and relationTitle must be strings');
            return;
        }

        let db = get(this._relationDb, `${namespaceTitle}.${relationTitle}`, false);
        if (db == null || db === false) {
            db = this.abstractDbRoot(namespaceTitle, relationTitle);
            set(this._relationDb, `${namespaceTitle}.${relationTitle}`, db);
        }
    }

    /**
     * Drop the indicated collection, and reset database pointer to not set
     * @param {string} namespaceTitle
     * @param {string} relationTitle
     */
    async dropCollection(namespaceTitle, relationTitle) {
        // On startup the database might not be initialized, but the
        // collections might exist in loki.
        const dropped = await this.abstractDbRoot.dropCollection(namespaceTitle, relationTitle);
        if (dropped) {
            // Don't bother if the database has not been initialized yet.
            if (get(this._relationDb, [namespaceTitle, relationTitle], false)) {
                set(this._relationDb, `${namespaceTitle}.${relationTitle}`, false);
            }
        }
        return dropped;
    }

    /**
     * Encapsulate getting sync/query stats for a namespace and relation
     * @param {string} namespaceTitle
     * @param {string} relationTitle
     */
    getStorageState(namespaceTitle, relationTitle, propertyName) {
        if (propertyName) {
            return get(
                this.storageState,
                `${namespaceTitle}.${relationTitle}['${propertyName}']`,
                initialDatabaseStats[propertyName]
            );
        }
        return get(this.storageState, `${namespaceTitle}.${relationTitle}`, initialDatabaseStats);
    }

    /**
     * Encapsulate setting sync/query stats for a namespace and relation
     * @param {string} namespaceTitle
     * @param {string} relationTitle
     * @param {string} propertyName
     * @param {*} value value to change
     */
    setStorageState(namespaceTitle, relationTitle, propertyName, value) {
        set(this.storageState, `${namespaceTitle}.${relationTitle}.${propertyName}`, value);
        set(this.allStorageState, `${this.settings.useCaseId}.${this.settings.tenantId}`, this.storageState);
        return this.persistStorageState();
    }

    resetStorageState(namespaceTitle, relationTitle, newStateToMerge) {
        // Save update sequence, so it doesn't think the database is new every time. See isNewDatabase().
        set(this.storageState, `${namespaceTitle}.${relationTitle}`, {
            ...initialDatabaseStats,
            ...newStateToMerge
        });
        set(this.allStorageState, `${this.settings.useCaseId}.${this.settings.tenantId}`, this.storageState);
        return this.persistStorageState();
    }

    /**
     * @param {string} namespaceTitle
     * @param {string} relationTitle
     */
    async isNewDatabase(namespaceTitle, relationTitle) {
        const loki = await this.abstractDbRoot.waitTillDbReady;
        return !loki.collections.some(col => col.name === `${namespaceTitle}_${relationTitle}`);
    }

    // Keep success dispatches in a consistent format.
    dispatch(result, payload, context) {
        if (result == null || !Array.isArray(result)) {
            throw new Error('All results must be an array.  Send an empty array for no results.');
        }
        //now inform the client (optimistically) about this change.
        //unless specified differently, assume success
        this.publish({ ...payload, result }, { status: 'success', ...context });
    }
    processSuccess(result, payload) {
        if (result == null || !Array.isArray(result)) {
            throw new Error('All results must be an array.  Send an empty array for no results.');
        }
        //if action happened locally, send it also over to the server to replay it there for permanence
        if (payload.offlineAction) {
            offlineResilientCommunication.sendWithOfflineResilience(payload.offlineAction);
        }
        return result;
    }

    getDatabaseRoot() {
        let { settings, setStorageState, storageState } = this;
        const root = new firepants('bb' + storageState.sequence, settings);
        // If a partition was lost, remove the corresponding synchronization information so that it will
        // resync immediately.
        root.waitTillDbReady.then(root => {
            if (root.persistenceAdapter?.failedPartitions?.length > 0) {
                root.persistenceAdapter.failedPartitions.forEach(c => {
                    const [namespace, relation] = c.collectionName.split('_');
                    if (!metadata.isLocalOnly({ title: namespace }, { title: relation }, settings.useCaseId)) {
                        setStorageState(namespace, relation, 'syncStartTime', undefined);
                        setStorageState(namespace, relation, 'initialSyncFinished', undefined);
                        setStorageState(namespace, relation, 'lastSyncTime', undefined);
                        setStorageState(namespace, relation, 'lastBatch', undefined);
                    }
                });
                // Remove failed partitions names so they aren't fully resynced again on the next sync.
                root.persistenceAdapter.failedPartitions = [];
            }
        });
        return root;
    }

    resumePhysicalWrites() {
        return this.abstractDbRoot.resumePhysicalWrites();
    }
    pausePhysicalWrites() {
        return this.abstractDbRoot.pausePhysicalWrites();
    }
}

export default Database;
