/**
 * Helper functions for common dispatch patterns
 */

/**
 * Cancels a sensor read and item operation
 * @param {Function} dispatch - The dispatch function
 * @param {Object} data - The data object containing markText information
 */
export const cancelSensorReadAndItem = (dispatch, data = {}) => {
    dispatch(
        { tagId: data.originalMarkText ?? data.markText },
        { verb: 'remove', namespace: 'sensor', relation: 'read' }
    );
    dispatch({}, { verb: 'cancel', namespace: 'item', relation: 'item' });
};
