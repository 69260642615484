import { network, session, auth0 } from 'lib_ui-services';

const _private = {
    changePassword: auth0.changePassword,
    logout: session.logout,
    getNetworkStatus: network.getStatus,
    confirmation
};
export default {
    verb: 'doingCreate',
    namespace: 'security',
    relation: 'profile',
    type: 'reset',
    prerequisites: [],
    description: 'Reset a user password',
    //this is the actual logic:
    logic: reset,
    _private
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function reset({ context, dispatch }) {
    const networkStatus = await _private.getNetworkStatus();
    if (networkStatus.isOnline) {
        const confirmed = await _private.confirmation(dispatch);
        if (!confirmed) {
            // User cancelled password reset
            return;
        }
        await _private.changePassword(context.session.profile.userName);
        _private.logout();
    } else {
        throw new Error('Unable to reset password while offline. Please go online to initiate a password reset.');
    }
}

/**
 * This function prompts the user to confirm the password reset action
 * @param {Function} dispatch - for publishing events
 * @returns Promise<boolean>
 */
async function confirmation(dispatch) {
    return new Promise(resolve =>
        dispatch(
            {
                message:
                    'This will log you out of the application and send a change password email to your account.  Continue?',
                okButtonText: 'CONTINUE',
                cancelButtonText: 'CANCEL',
                okAction: () => resolve(true),
                cancelAction: () => resolve(false),
                jumpQueue: true
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        )
    );
}
