/**
 *
 * Generates the applicable basic filter object for the given paths and filterValues
 *  ```
 * {
 *     'location:building._id': { $in: ['1234', '5678'] }
 * }
 * ```
 * Or, if the role has access to records without the value
 * ```
 * {
 *     $or:[
 *            {'location:building._id': { $in: ['1234', '5678'] }},
 *            {'location:building._id': { $exists:false }}
 *         ]
 *     }
 * }
 * ```
 * @param {Array<string>} paths - Array of paths to filter
 * @param {Array<{_id:string}>} filterValues - Array of values to filter by
 * @returns {object} - filter object
 */
export default function foreignKeyFilter(paths, filterValues) {
    if (!filterValues?.length || !paths?.length) return undefined;
    // separate the actual filter values from the special case of allowing undefined values
    const { actualValues, allowUndefined } = filterValues.reduce(
        (acc, { _id }) => {
            if (_id === '0'.repeat(24)) {
                return { ...acc, allowUndefined: true };
            }
            acc.actualValues.push(_id);
            return acc;
        },
        { actualValues: [], allowUndefined: false }
    );

    // If we only have zero IDs (allowUndefined is true but no actualValues),
    // return a simple exists:false filter
    if (allowUndefined && actualValues.length === 0) {
        return paths.length === 1
            ? { [paths[0]]: { $exists: false } }
            : { $or: paths.map(path => ({ [path]: { $exists: false } })) };
    }

    const criteria =
        actualValues.length > 1
            ? {
                  $in: actualValues
              }
            : {
                  $eq: actualValues[0]
              };

    // construct the actual filter
    const filter = paths.flatMap(path => {
        const clause = [
            {
                [path]: criteria
            }
        ];
        if (allowUndefined) {
            clause.push({ [path]: { $exists: false } });
        }
        return clause;
    });
    // nest it in an $or if there are multiple clauses
    return filter.length === 1 ? filter[0] : { $or: filter };
}
