import { createElement as rc, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import useFormControl from '../../hooks/useFormControl';
import { DropZone, Chip, View, styled, fromTheme, Button, ErrorBoundary } from 'lib_ui-primitives';
import FormField from './FormField';
import SimpleDialog from '../dialog/SimpleDialog';
import useEventSink from '../../hooks/useEventSink';
import Camera from 'lib_ui-primitives/src/components/camera/Camera';

const FilePickerControl = styled(FormField).attrs({ name: 'FilePicker' })`
    max-width: 100%;
`;
FilePickerControl.displayName = 'FilePicker';

const TopToBottom = styled(View)`
    flex-direction: column;
`;
const LeftToRight = styled(View)`
    flex-direction: row;
`;
const AlignRight = styled(View)`
    flex-direction: row;
    justify-content: flex-end;
`;

// Always false so that label floats permanently (instead of overlapping the control).
const fieldEmpty = false;
const active = false;

const FileContainer = styled(Chip.Container).attrs({ name: 'selected-file-list' })`
    margin-top: ${fromTheme('textMargin')};
`;

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];
const _p = {
    useFormControl
};
export const _private = _p;

const FilePicker = props => {
    const {
        currentRoute,
        hNode: { id, propertyName, tooltip, mimeTypes, allowMultiple = false, allowTakingPictures = false }
    } = props || { hNode: {} };
    const { title, value, setValue, disabled } = _p.useFormControl(props, allowMultiple ? EMPTY_ARRAY : EMPTY_OBJECT);
    const [errors, setFileErrors] = useState(EMPTY_ARRAY);
    const [photo, setPhoto] = useState(EMPTY_OBJECT);

    const [, publish] = useEventSink();

    const onInvalidFileSelected = useCallback(x => setFileErrors(x.map(e => e.message)), [setFileErrors]);

    const onFileSelected = useCallback(
        /**
         *
         * @param {object[]} acceptedFiles
         * @param {string} acceptedFiles[].name file name including extension
         * @param {number} acceptedFiles[].size file size in bytes
         * @param {string} acceptedFiles[].type mime type. e.g. `text/csv`
         * @param {Date} acceptedFiles[].lastModifiedDate
         */
        acceptedFiles => {
            if (acceptedFiles?.length) {
                setValue(allowMultiple ? prev => prev?.concat(acceptedFiles) || acceptedFiles : [acceptedFiles[0]]);
                setFileErrors();
            } else {
                setValue(EMPTY_ARRAY);
            }
        },
        [allowMultiple, setValue]
    );

    const clearSelection = useCallback(
        removedIndex => {
            if (!value.length) return;
            if (value?.length === 1) {
                setValue(EMPTY_ARRAY);
                return;
            }
            const newValue = value.filter((value, i) => i !== removedIndex);
            setValue(newValue);
        },
        [setValue, value]
    );

    let dialogHNode = {
        id: `dialog${id}`,
        viewport: ['all'],
        hNodeType: 'SimpleDialog',
        hNodeTypeGroup: 'dialog',
        title: 'Take Photo',
        closeAction: 'close',
        openAction: 'open',
        namespace: 'application',
        relation: 'photo',
        openActionType: { verb: 'open', namespace: 'application', relation: 'photo' },
        closeActionType: { verb: 'close', namespace: 'application', relation: 'photo' }
    };

    const fileSelected = !!value?.length;
    const displayDropZone = allowMultiple || !fileSelected;
    // prettier-ignore
    return rc(FilePickerControl, { id, title, errors, active, fieldEmpty, name:'FilePicker'},
        rc(TopToBottom,  null,
            displayDropZone && rc(LeftToRight,{},
                rc(DropZone, {id, title:tooltip, mimeTypes, onFileSelected , onInvalidFileSelected, allowMultiple, disabled, name: propertyName}),
                allowTakingPictures && rc(Button, { 
                    icon:'photo_camera', 
                    iconSize:'40px', 
                    buttonStyle:'Gray',
                    onClick: () => publish(null, dialogHNode.openActionType) 
                })
            ),
            fileSelected && rc(FileContainer, {},
                value.map((file,i) => rc(Chip,  { key: `chip${i}`, id:`chip${i}`, value: file.name, onClick: ()=>clearSelection(i) })
            )
        ),
        rc(SimpleDialog, { hNode: dialogHNode, currentRoute: currentRoute },
            rc(ErrorBoundary, null,
                rc(Camera, {id:'FilePickerCamera', onCapture:setPhoto}),
                rc(AlignRight, {},
                    rc(Button, {
                        id: `close${id}`,
                        value: 'CANCEL',
                        buttonStyle: 'AccentOne',
                        hNode: {
                            hNodeType: 'FormButton',
                            hNodeBlockGroup: 'formButtons',
                            hNodeTypeGroup: 'formButton'
                        },
                        onClick: () => publish(null, dialogHNode.closeActionType)
                    }),
                    rc(Button, {
                        id: `select${id}`,
                        value: 'Use Photo',
                        buttonStyle: 'Positive',
                        disabled:!photo.content,
                        hNode: {
                            hNodeType: 'FormButton',
                            hNodeBlockGroup: 'formButtons',
                            hNodeTypeGroup: 'formButton'
                        },
                        onClick: () => {
                            onFileSelected([photo]);
                            publish(null, dialogHNode.closeActionType);
                        }
                    })
                )    
            )
        )
    ));
};

FilePicker.propTypes = {
    hNode: PropTypes.shape({
        id: PropTypes.string,
        propertyName: PropTypes.string.isRequired,
        tooltip: PropTypes.string,
        mimeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
        allowMultiple: PropTypes.bool
    }).isRequired
};

export default FilePicker;
