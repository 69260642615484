import SUPPORT_URL from './SUPPORT_URL';

export default Object.freeze({
    sst: { displayName: 'SST', supportPhone: '678-389-7200' },
    redbeam: {
        displayName: 'RedBeam',
        supportPhone: '877-373-0390',
        knowledgeBase: SUPPORT_URL.redbeam.knowledgeBase
    }
});
