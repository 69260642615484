import { createElement as rc, Fragment, useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from './Slider';
import { View, Portal, Button, styled, hooks, h3 } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
import { useTheme } from 'styled-components';
import useEventSink from '../../hooks/useEventSink';
import logging from '@sstdev/lib_logging';
import SensorReadButton from './SensorReadButton';
import useReads from '../../hooks/useReads';
import useActiveRecord from '../../hooks/useActiveRecord';
import ReadProvider from '../contextProviders/ReadProvider';

const { usePersistentState } = hooks;
const _p = {
    usePersistentState,
    useReads
};
export const _private = _p;
const _PowerSliderPopup = styled(Portal).attrs({ name: 'power-slider-popup' })`
    flex-direction: column;
`;
const PowerSliderPopupContainer = styled(View).attrs({ name: 'power-slider-popup-container' })`
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
`;
PowerSliderPopupContainer.displayName = 'PowerSliderPopupContainer';
const TopRow = styled(View).attrs({ name: 'top-row' })`
    flex-direction: row;
`;
TopRow.displayName = 'TopRow';

PowerSliderPopup.displayMode = Object.freeze({
    BUTTON_TRIGGER: 'button',
    EVENT_TRIGGER: 'event'
});

const desiredSensorTypes = [constants.sensorTypes.RFID];
export default function PowerSliderPopup(props) {
    const id = props.hNode?.id ?? props.id;

    // If there is an active record, then we are probably in a detail pane and should use a new
    // ReadProvider to isolate our reads (e.g. away from the summary view reads).
    // The reads will be published via a success status event (see below)
    const { record } = useActiveRecord();
    if (record != null) {
        return rc(
            ReadProvider,
            { id, desiredSensorTypes, debugText: 'PowerSliderPopup' },
            rc(InnerPowerSliderPopup, props)
        );
    }
    return rc(InnerPowerSliderPopup, props);
}

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/**
 * This can either be a dialog which is displayed using a metadata configured event
 * or it can be a visible button that displays the dialog when clicked.
 * The dialog will allow the user to adjust the RFID power or (if the displayScanButton
 * property is true) perform a scan.  The user can also use a hardware trigger button
 * to scan.
 * @type {import('react').FC<Props>}
 * */
function InnerPowerSliderPopup(props) {
    const { button } = useTheme();
    const { hNode, currentRoute } = props ?? { hNode: {} };
    const displayMode = hNode?.displayMode ?? PowerSliderPopup.displayMode.BUTTON_TRIGGER;
    const displayScanButton = hNode?.displayScanButton || props.displayScanButton;
    const title = hNode?.title ?? props.title;
    const id = hNode?.id ?? props.id;

    const [portalOpen, setPortalOpen] = useState(false);
    const [subscribe, publish] = useEventSink();
    const { reads, sensorTypesAvailable, reading } = _p.useReads();
    const ref = useRef();

    const onClick = useCallback(
        function onClick() {
            setPortalOpen(prev => {
                if (!prev) {
                    publish(
                        { sensorType: constants.sensorTypes.RFID },
                        { verb: 'reset', namespace: 'sensor', relation: 'read' }
                    );
                } else if (displayMode === PowerSliderPopup.displayMode.EVENT_TRIGGER) {
                    publish(
                        { errors: { form: ['RFID read cancelled.'], field: {} } },
                        {
                            ...originalTriggerContext.current,
                            status: 'failure'
                        }
                    );
                }
                return !prev;
            });
        },
        [publish, displayMode]
    );

    const originalTriggerContext = useRef();
    // Open the portal/popup if the metadata configured event is received
    useEffect(() => {
        if (displayMode === PowerSliderPopup.displayMode.EVENT_TRIGGER && !portalOpen) {
            subscribe(
                { namespace: hNode.namespace, relation: hNode.relation, verb: hNode.forAction, type: hNode.subtype },
                (x, context) => {
                    originalTriggerContext.current = context;
                    publish(
                        { sensorType: constants.sensorTypes.RFID },
                        { verb: 'reset', namespace: 'sensor', relation: 'read' }
                    );
                    setPortalOpen(true);
                }
            );
        }
    }, [displayMode, subscribe, hNode, portalOpen, publish]);

    // If an open portal/popup is event triggered and we have finished gathering reads,
    // then close the portal and publish a success with the reads.
    const readOccurred = useRef(false);
    useEffect(() => {
        if (reading) {
            readOccurred.current = true;
        } else if (displayMode === PowerSliderPopup.displayMode.EVENT_TRIGGER && readOccurred.current) {
            readOccurred.current = false;
            const _reads = reads.get();
            setPortalOpen(false);
            publish(
                { reads: _reads },
                {
                    ...originalTriggerContext.current,
                    status: 'success'
                }
            );
        }
    }, [displayMode, hNode, portalOpen, reads, publish, reading]);

    // This is used only to retrieve the previously stored slider value (not to set it).
    // The value is needed so that the current reader power can be set to the previously
    // stored value even if the actual slider is never rendered (e.g. the button is
    // displayed, but never clicked).
    const [value, , ready] = _p.usePersistentState(`psp-slider-${id}`, 100);
    useEffect(() => {
        if (ready) {
            publish(
                { power: value },
                { verb: 'change', namespace: 'sensor', relation: 'service', routePath: currentRoute }
            );
            logging.debug(`[SLIDER] publishing ${value}`);
        }
        // Only emit the value once (after it is retrieved from persistent storage)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ready, publish]);

    // If portal is unmounted by clicking the background, set the PortalOpen property correctly
    const onUnmount = useCallback(() => {
        setPortalOpen(false);
    }, []);

    if (!sensorTypesAvailable.includes(constants.sensorTypes.RFID)) {
        return null;
    }

    // prettier-ignore
    return rc(Fragment, null,
        displayMode === PowerSliderPopup.displayMode.BUTTON_TRIGGER && rc(Button, {
            ref,
            onClick,
            value: 'Power',
            icon: 'signal-distance-variant',
            buttonStyle: 'IconAction',
            alt: 'Set power of sensor',
            id: `psp-button-${id}`,
            fontColor: button.fontColor
        }),
        portalOpen && rc(_PowerSliderPopup, { id: `psp-${id}`, onUnmount },
            rc(PowerSliderPopupContainer, null,
                title && rc(h3, null, title),
                rc(TopRow, null,
                    rc(Slider, {
                        hNode: {
                            id: `psp-slider-${id}`,
                            title: 'Power',
                            namespace: 'sensor',
                            relation: 'service',
                            forAction: 'change',
                            propertyName: 'power',
                            max: 100,
                            min: 0,
                            defaultValue: 100
                        }
                    }),
                    rc(Button, {
                        onClick,
                        value: 'Close',
                        icon: 'close',
                        alt: 'Close',
                        id: `psp-button-close-${id}`,
                        buttonStyle: 'round',
                        fontColor: button.fontColor,
                        color: 'transparent',
                        displayTitleOnButton: false
                    })
                ),
                displayScanButton && rc(SensorReadButton)
            )
        )
    );
}

InnerPowerSliderPopup.defaultProps = {};

InnerPowerSliderPopup.propTypes = {
    sensorTypesAvailable: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    title: PropTypes.string,
    currentRoute: PropTypes.string.isRequired,
    displayScanButton: PropTypes.bool,
    hNode: PropTypes.shape({
        displayMode: PropTypes.oneOf([
            PowerSliderPopup.displayMode.BUTTON_TRIGGER,
            PowerSliderPopup.displayMode.EVENT_TRIGGER
        ]),
        namespace: PropTypes.string,
        relation: PropTypes.string,
        forAction: PropTypes.string,
        subtype: PropTypes.string,
        title: PropTypes.string,
        displayScanButton: PropTypes.bool,
        id: PropTypes.string
    })
};
