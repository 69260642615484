// @ts-check
// Copied from lib_ui-components/src/components/contextProviders/MergedReadProvider/mergeStrategies/inaccessibleTag.js
import { filters, metadata } from 'lib_ui-services';
import lodash from 'lodash';

const { memoize } = lodash;

export const _private = {
    getDataDictionary: metadata.getDictionary
};

/**
 * Checks if a user has access to records based on their data rights
 * @param {string} namespace - The namespace for the data model
 * @param {string} relation - The relation for the data model
 * @param {Object} userRole - The user role object containing data rights
 * @param {boolean} clientDataRights - Whether to check client data rights
 * @returns {Promise<{hasAccess: Function}>} - Returns an object with a hasAccess function
 */
async function accessChecker(namespace, relation, userRole, clientDataRights) {
    if (!userRole?.dataRights || !clientDataRights) {
        return {
            hasAccess: () => true
        };
    }
    const dataDictionary = await _private.getDataDictionary();
    const dataModel = dataDictionary?.[namespace]?.[relation];
    const filterCriteria = filters.createDataFilter(namespace, relation, dataModel, userRole);

    const hasAccess = filters.filterHelpers.criteriaToJavaScript(filterCriteria);

    return {
        hasAccess
    };
}

export { accessChecker };
export default memoize(accessChecker);
