/**
 * @module lib_ui-services/session
 * @description This keeps track of the user's session, including their profile, use case, and tenant.
 * The session also tracks whether the user has accepted the EULA and MLA for the active use case and
 * tenant, and the deployment group number used by the tenant.
 *
 */
import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
import globalConfig from '../globalConfig';
import getRouter from '../getRouter';
import {
    saveProfile,
    getProfile,
    getActiveUseCaseAndTenant,
    setActiveProfileId,
    setActiveUseCaseAndTenant as _setActiveUseCaseAndTenant,
    clearActiveProfileId,
    clearActiveUseCaseAndTenant,
    getProfileWithUserNameAndPin,
    onCacheChange,
    profileWithPinExists
} from './sessionCache';
import getIfEulaAndMlaAccepted from './getIfEulaAndMlaAccepted';
import getIfContractExpired from './getIfContractExpired';
import simpleChangeObserver from '../simpleChangeObserver';
import { getStatus } from '../network';
import getFullURL from '../http/getFullURL';
import * as auth0 from '../auth0';

const { capitalize } = lodash;
const { onChange, publishChange } = simpleChangeObserver();

let _p = {
    _groupPath: '',
    auth0,
    auth0Init: auth0.init,
    auth0Login: auth0.login,
    auth0Logout: auth0.logout,
    auth0GetUser: auth0.getUser,
    auth0IsAuthenticated: auth0.isAuthenticated,
    clearActiveProfileId,
    clearActiveUseCaseAndTenant,
    getActiveUseCaseAndTenant,
    getBackboneUser,
    getIfEulaAndMlaAccepted,
    getNetworkStatus: getStatus,
    getProfile,
    getProfileWithUserNameAndPin,
    getRouter,
    getSessionData,
    globalConfig,
    httpGet: rawGet,
    login,
    logout,
    onCacheChange,
    profileWithPinExists,
    saveProfile,
    setActiveProfileId,
    setActiveUseCaseAndTenant: _setActiveUseCaseAndTenant,
    getIfContractExpired
};

export const _private = _p;

export const onSessionChange = onChange;

export async function init() {
    const { protocol, host, groupNumber } = _p.getRouter();
    _p._groupPath = groupNumber ? `/g/${groupNumber}` : '/g/0';
    await _p.auth0Init(protocol, host, _p._groupPath);

    const networkStatus = await _p.getNetworkStatus();
    let profile, session;
    if (networkStatus.isServerReachable) {
        // automatically redirect to the Auth0 login page.
        profile = await _p.login();
        if (profile == null) {
            // Probably just redirected to the login page.
            return;
        }
        // TODO: Fix this on the server side so that there is JUST _id - no userId.
        await _p.setActiveProfileId(profile._id ?? profile.userId);
        await _p.saveProfile(profile);
        session = await _p.getSessionData(profile);
    } else {
        // We are offline, so we cannot login to Auth0
        // Check to see if we have an active profile
        const throwIfMissing = false;
        const profile = await _p.getProfile(throwIfMissing);
        if (profile != null) {
            // We have a profile, so go ahead and create a session
            session = await _p.getSessionData(profile);
        } else {
            // We have no active profile, so we need to check if we have a profile with an offline pin
            const profileWithPinExists = await _p.profileWithPinExists();
            if (!profileWithPinExists) {
                throw new Error(
                    'This device appears to have no network connection and no previous login.  You will need to connect to the internet and login at least once to use this application.'
                );
            }
            // Should trigger the offline pin page
            session = { useOfflinePin: true };
        }
    }
    // Listen for changes to the session cache
    createSessionSubscriptions();

    return session;
}

export async function createSessionSubscriptions() {
    _p.onCacheChange(async () => {
        // If we are logging out, then we don't want to update the session.
        if (_p.loggingOut) return;
        try {
            // Update the session data.  Right now, this is mainly for the SessionProvider.
            const session = await _p.getSessionData();
            const { groupNumber } = session ?? {};
            // A different group number (than that in the url) could existing in the tenant, so
            // we need to update the group path.
            _p._groupPath = groupNumber ? `/g/${groupNumber}` : '';
            publishChange('session', session);
        } catch (error) {
            logging.error(`[SESSION] Error updating session: ${error.message}`);
        }
    });
}

/**
 * Retrieves session data based on the provided profile.
 * If no profile is provided, it retrieves the profile using _p.getProfile().
 * It then retrieves the active use case and tenant using _p.getActiveUseCaseAndTenant(profile).
 * Finally, it checks if the EULA and MLA are accepted for the use case and tenant using _p.getIfEulaAndMlaAccepted(useCase, tenant).
 * The group number is extracted from the tenant object.
 * Returns an object containing the group number and the session data.
 *
 * @param {import('../../../types').Profile} [_profile] - The user profile.
 * @returns {Promise<import('../../../types').Session>} - An object containing the session data.
 */
export async function getSessionData(_profile) {
    const profile = _profile ?? (await _p.getProfile());
    if (profile == null) {
        logging.error('[SESSION] No profile found.');
        return _p.logout();
    }
    let eulaAccepted, mlaAccepted, groupNumber, role, allFeatureFlags, contractExpired;
    const { useCase, tenant } = (await _p.getActiveUseCaseAndTenant(profile)) ?? {};
    if (useCase != null && tenant != null) {
        ({ eulaAccepted, mlaAccepted } = _p.getIfEulaAndMlaAccepted(useCase) ?? {});
        contractExpired = _p.getIfContractExpired(tenant);
        groupNumber = useCase['deploy:group']?.title[1] ?? 0;
        role = useCase['identity:role'];
        allFeatureFlags = useCase.featureFlags ?? [];
    }

    const briefUserReference = { _id: profile._id ?? profile.userId, displayName: profile.displayName };
    const session = {
        briefUserReference,
        profile,
        useCase,
        tenant,
        eulaAccepted,
        mlaAccepted,
        contractExpired,
        groupNumber,
        role,
        allFeatureFlags,
        loggingOut: !!_p.loggingOut,
        // Right now this is true when the user elects to request a new tenant.  Reloading
        // the page clears the old tenant data from the in-memory portion of loki, etc.
        reloadingPage: !!_p.reloadingPage
    };
    return session;
}

export async function isAuthenticated() {
    return _p.auth0IsAuthenticated();
}
export async function login() {
    const isAuthenticated = await _p.auth0IsAuthenticated();
    if (!isAuthenticated) {
        // This will display the Auth0 login page.
        // On web this is an actual redirect, so this function will not return.
        // On native, this will return a promise that resolves when the user is logged in.
        const willRedirect = await _p.auth0Login();
        if (willRedirect) return;
    }
    logging.debug('[SESSION] User is authenticated.  Getting user data.');
    const auth0User = await _p.auth0GetUser();
    if (!auth0User) {
        throw new Error('Logged in, but no user found.');
    }
    const backboneUser = await _p.getBackboneUser(auth0User);
    // eslint-disable-next-line no-undef
    if (!__SST_REACT_NATIVE__ && backboneUser == null && globalConfig().brand === 'redbeam') {
        let name, firstname, lastname, company;
        [, name, company] = auth0User.email.match(/(.+)@(.+)\..+/);
        if (auth0User.name && auth0User.name !== auth0User.email) {
            name = auth0User.name;
        }
        // heuristics to split the name into first and last name
        [firstname, lastname] = name.split(' ');
        if (!lastname) {
            [firstname, lastname] = name.split('.');
        }
        if (!lastname) {
            lastname = firstname;
        }
        lastname = capitalize(lastname ?? '');
        firstname = capitalize(firstname ?? '');
        company = capitalize(company ?? '');
        // Remove auth0 login session and send user to signup page.
        const redirectUrl = `https://redbeam.com/try-it-now?company=${company}&email=${auth0User.email}&lastname=${lastname}&firstname=${firstname}`;
        await _p.auth0Logout(true, redirectUrl);
        return;
    } else {
        // No backbone user found.
        if (!backboneUser || !backboneUser.tenant?.[0].useCase?.[0]) {
            throw new Error('The user was authenticated, but no access was found for this user.');
        }
    }
    // If the user has selected a tenant/usecase, check if the contract has expired
    const tenant = backboneUser?.activeTenant;
    if (_p.getIfContractExpired(tenant)) {
        // If the contract has expired, redirect to the contract expired page
        // All session data will be cleared when the user is redirected to the contract expired page
        _p.getRouter().goToLocation('/contractExpired');
    }
    return { ...auth0User, ...backboneUser };
}

export async function loginWithPin(payload) {
    const { email, pin } = payload;
    const userName = email;

    if (userName == null || userName === '') {
        return Promise.reject(new Error('An email must be provided.'));
    }
    if (pin == null || pin === '') {
        return Promise.reject(new Error('A pin must be provided.'));
    }
    const profile = await _p.getProfileWithUserNameAndPin(userName, pin);
    if (profile == null) {
        return Promise.reject(new Error('Invalid email or pin.'));
    }
    await _p.setActiveProfileId(profile._id ?? profile.userId);
    const session = await _p.getSessionData(profile);
    publishChange('session', session);
    return Promise.resolve(session);
}

export async function getToken() {
    return _p.auth0.getTokenSilently();
}

export async function logout(clearSessionCache = true) {
    // Prevents the selectTenant immediately when the tenant is cleared.
    _p.loggingOut = true;
    if (clearSessionCache) {
        logging.debug('[SESSION] Clearing session data.');
        await _p.clearActiveUseCaseAndTenant();
        await _p.clearActiveProfileId();
        // Currently only sessionStorage.js cares about this.
        publishChange('destroySession');
    }
    const status = await _p.getNetworkStatus();
    logging.info('[SESSION] Logging out');
    if (status.isServerReachable) {
        // If this is not react native, this will redirect to the login page.
        await _p.auth0Logout();
        // eslint-disable-next-line no-undef
        if (!__SST_REACT_NATIVE__) return;
    } else {
        // This will clear tokens etc, but not redirect to the auth0 login page.
        await _p.auth0Logout(false);
    }
    // Only gets here if this is react native or the server is unreachable.  Otherwise,
    // the auth0Logout will redirect to the auth0 login page.
    _p.loggingOut = false;
    // Create a new session - if offline this will end up on the offline pin page.
    const session = await init();
    publishChange('session', session);
}

async function getBackboneUser() {
    const token = await _p.auth0.getTokenSilently({ detailedResponse: true });
    const url = getFullURL('/api/identity/self');
    let backboneUser = await _p.httpGet(url, {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    });
    return backboneUser;
}

//we need to bypass the http.get functionality, as otherwise we'd end up with a circular reference
async function rawGet(url, headers) {
    const params = {
        method: 'GET',
        headers
    };
    const response = await fetch(url, params);
    if (response.ok) {
        return response.json();
    } else {
        logging.error(`[AUTHENTICATION] Failed to authorize. ${response.status} - ${response.statusText}`);
    }
}

export async function setActiveUseCaseAndTenant(activeUseCaseAndTenant) {
    // This can be confusing so check duck type
    if (!activeUseCaseAndTenant?.useCase || !activeUseCaseAndTenant?.tenant) {
        throw new Error('Invalid use case and tenant object.');
    }
    await _p.setActiveUseCaseAndTenant(activeUseCaseAndTenant);
}

export async function setLicenseAccepted(acceptedLicense) {
    const { type } = acceptedLicense;
    const { useCase, tenant } = await _p.getActiveUseCaseAndTenant();
    const eulaAccepted = type === 'EULA',
        mlaAccepted = type === 'MLA';

    // update the active tenants use case with the accepted license
    const profile = await _p.getProfile();
    const tenantIndex = profile.tenant.findIndex(t => t['identity:tenant']._id === tenant['identity:tenant']._id);
    if (tenantIndex === -1) {
        throw new Error(
            'Tenant not found for accepted license.  There may be a problem with the user profile configuration.'
        );
    }
    const useCaseIndex = profile.tenant[tenantIndex].useCase.findIndex(u => u._id === useCase._id);
    if (useCaseIndex === -1) {
        throw new Error(
            'A use case was not found for the accepted license.  There may be a problem with the user profile configuration.'
        );
    }
    if (eulaAccepted) {
        profile.tenant[tenantIndex].useCase[useCaseIndex].needEulaAcceptance = false;
        useCase.needEulaAcceptance = false;
    }
    if (mlaAccepted) {
        profile.tenant[tenantIndex].useCase[useCaseIndex].needMlaAcceptance = false;
        useCase.needMlaAcceptance = false;
    }
    // Update these too so that the session object is updated correctly.
    await setActiveUseCaseAndTenant({ useCase, tenant });
    // This should trigger a cascade of events that will update the session object.
    await _p.saveProfile(profile);
}

/**
 * Clears the active session data including the selected use case and tenant.
 */
export async function clearSessionData() {
    publishChange('destroySession');
    await _p.clearActiveUseCaseAndTenant();
}

export const cache = { saveProfile, getProfile };
