// https://github.com/redux-offline/redux-offline/blob/develop/docs/api/config.md#effect
import * as http from '../http/index';
import { isAuthenticated, login } from '../session';
import NotLoggedInError from '../errors/NotLoggedInError';
import { wait } from '../asyncUtilities';
import getGlobalConfig from '../globalConfig';
import logging from '@sstdev/lib_logging';

export const _private = {
    http,
    isAuthenticated,
    login,
    wait
};

let _eventSink;
export default function getOfflineEffectOverride(eventSink) {
    _eventSink = eventSink;
    return async (effect, action) => {
        await checkIfOkToProceed();
        const [verb] = action.type.split('_');
        switch (verb) {
            case 'update':
                return _private.http.patch(effect.path, effect.patch, effect.httpHeaders);
            case 'create':
                return _private.http.post(effect.path, effect.newRecord, effect.httpHeaders);
            case 'remove':
                return _private.http.remove(effect.path, effect.httpHeaders);
            default:
                throw new Error(`Effect verb '${verb}' has not been implemented.`);
        }
    };
}

async function checkIfOkToProceed() {
    const loggedIn = await _private.isAuthenticated();
    if (!loggedIn) {
        logging.debug('[OFFLINE_EFFECT] User is not logged in.  Checking if it is ok to login.');
        await isOkToLogin();
        // This will redirect to the Auth0 login page and then back to the app
        logging.debug('[OFFLINE_EFFECT] Ok to login.  Attempting to login.');
        await _private.login();
    }
    return true;
}

const timeout = 100; //mills
// This will preceed the retry in the decaying schedule as defined in
// './retryOverride.js'
const defaultdelayBeforeRetry = getGlobalConfig().timeout.loginRetryAfterUserBusy;
const errorWhenNoSubscribers = false;
async function isOkToLogin() {
    // Right now this is just consumed by the useFormMachine which checks if the
    // user has a dirty form.
    const { isOk, delayReason, delayBeforeRetry } = await _eventSink.request(
        {},
        { verb: 'confirm', namespace: 'application', relation: 'runtime', type: 'okToLogin' },
        timeout,
        errorWhenNoSubscribers
    );
    if (!isOk) {
        logging.debug(`[OFFLINE_EFFECT] User is busy.  Delaying login.  Reason: ${delayReason}`);
        // Wait a while and then throw an error that will retry the login
        await _private.wait(delayBeforeRetry ?? defaultdelayBeforeRetry);
        throw new NotLoggedInError(delayReason);
    }
}
